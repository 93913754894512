const NEMSIS_KEY_CODES = {
    "eRecord.01": "Patient Care Report Number",
    "eRecord.02": "Software Creator",
    "eRecord.03": "Software Name",
    "eRecord.04": "Software Version",
    "eResponse.01": "EMS Agency Number",
    "eResponse.02": "EMS Agency Name", 
    "eResponse.03": "Incident Number",
    "eResponse.04": "EMS Response Number",
    "eResponse.05": "Type of Service Requested",
    "eResponse.06": "Standby Purpose",
    "eResponse.07": "Unit Transport and Equipment Capability",
    "eResponse.08": "Type of Dispatch Delay",
    "eResponse.09": "Type of Response Delay",
    "eResponse.10": "Type of Scene Delay",
    "eResponse.11": "Type of Transport Delay",
    "eResponse.12": "Type of Turn-Around Delay",
    "eResponse.13": "EMS Vehicle (Unit) Number",
    "eResponse.14": "EMS Unit Call Sign",
    "eResponse.16": "Vehicle Dispatch Location",
    "eResponse.17": "Vehicle Dispatch GPS Location",
    "eResponse.18": "Vehicle Dispatch Location US National Grid Coordinates",
    "eResponse.19": "Beginning Odometer Reading of Responding Vehicle",
    "eResponse.20": "On-Scene Odometer Reading of Responding Vehicle",
    "eResponse.21": "Patient Destination Odometer Reading of Responding Vehicle",
    "eResponse.22": "Ending Odometer Reading of Responding Vehicle",
    "eResponse.23": "Response Mode to Scene",
    "eResponse.24": "Additional Response Mode Descriptors",
    "eDispatch.01": "Dispatch Reason",
    "eDispatch.02": "EMD Performed",
    "eDispatch.03": "EMD Card Number",
    "eDispatch.04": "Dispatch Center Name or ID",
    "eDispatch.05": "Dispatch Priority (Patient Acuity)",
    "eDispatch.06": "Unit Dispatched CAD Record ID",
    "eCrew.01": "Crew Member ID",
    "eCrew.02": "Crew Member Level",
    "eCrew.03": "Crew Member Response Role",
    "eTimes.01": "PSAP Call Date/Time",
    "eTimes.02": "Dispatch Notified Date/Time",
    "eTimes.03": "Unit Notified by Dispatch Date/Time",
    "eTimes.04": "Dispatch Acknowledged Date/Time",
    "eTimes.05": "Unit En Route Date/Time",
    "eTimes.06": "Unit Arrived on Scene Date/Time",
    "eTimes.07": "Arrived at Patient Date/Time",
    "eTimes.08": "Transfer of EMS Patient Care Date/Time",
    "eTimes.09": "Unit Left Scene Date/Time",
    "eTimes.10": "Arrival at Destination Landing Area Date/Time",
    "eTimes.11": "Patient Arrived at Destination Date/Time",
    "eTimes.12": "Destination Patient Transfer of Care Date/Time",
    "eTimes.13": "Unit Back in Service Date/Time",
    "eTimes.14": "Unit Canceled Date/Time",
    "eTimes.15": "Unit Back at Home Location Date/Time",
    "eTimes.16": "EMS Call Completed Date/Time",
    "eTimes.17": "Unit Arrived at Staging Area Date/Time",
    "ePatient.01": "EMS Patient ID",
    "ePatient.02": "Last Name",
    "ePatient.03": "First Name",
    "ePatient.04": "Middle Initial/Name",
    "ePatient.05": "Patient's Home Address",
    "ePatient.06": "Patient's Home City",
    "ePatient.07": "Patient's Home County",
    "ePatient.08": "Patient's Home State",
    "ePatient.09": "Patient's Home ZIP Code",
    "ePatient.10": "Patient's Country of Residence",
    "ePatient.11": "Patient Home Census Tract",
    "ePatient.12": "Social Security Number",
    "ePatient.13": "Gender",
    "ePatient.14": "Race",
    "ePatient.15": "Age",
    "ePatient.16": "Age Units",
    "ePatient.17": "Date of Birth",
    "ePatient.18": "Patient's Phone Number",
    "ePatient.19": "Patient's Email Address",
    "ePatient.20": "State Issuing Driver's License",
    "ePatient.21": "Driver's License Number",
    "ePatient.22": "Alternate Home Residence",
    "ePayment.01": "Primary Method of Payment",
    "ePayment.02": "Physician Certification Statement",
    "ePayment.03": "Date Physician Certification Statement Signed",
    "ePayment.04": "Reason for Physician Certification Statement",
    "ePayment.05": "Healthcare Provider Type Signing Physician Certification Statement",
    "ePayment.06": "Last Name of Individual Signing Physician Certification Statement",
    "ePayment.07": "First Name of Individual Signing Physician Certification Statement",
    "ePayment.08": "Patient Resides in Service Area",
    "ePayment.09": "Insurance Company ID",
    "ePayment.10": "Insurance Company Name",
    "ePayment.11": "Insurance Company Billing Priority",
    "ePayment.12": "Insurance Company Address",
    "ePayment.13": "Insurance Company City",
    "ePayment.14": "Insurance Company State",
    "ePayment.15": "Insurance Company ZIP Code",
    "ePayment.16": "Insurance Company Country",
    "ePayment.17": "Insurance Group ID",
    "ePayment.18": "Insurance Policy ID Number",
    "ePayment.19": "Last Name of the Insured",
    "ePayment.20": "First Name of the Insured",
    "ePayment.21": "Middle Initial/Name of the Insured",
    "ePayment.22": "Relationship to the Insured",
    "ePayment.58": "Insurance Group Name",
    "ePayment.59": "Insurance Company Phone Number",
    "ePayment.60": "Date of Birth of the Insured",
    "ePayment.23": "Closest Relative/Guardian Last Name",
    "ePayment.24": "Closest Relative/Guardian First Name",
    "ePayment.25": "Closest Relative/Guardian Middle Initial/Name",
    "ePayment.26": "Closest Relative/Guardian Street Address",
    "ePayment.27": "Closest Relative/Guardian City",
    "ePayment.28": "Closest Relative/Guardian State",
    "ePayment.29": "Closest Relative/Guardian ZIP Code",
    "ePayment.30": "Closest Relative/Guardian Country",
    "ePayment.31": "Closest Relative/Guardian Phone Number",
    "ePayment.32": "Closest Relative/Guardian Relationship",
    "ePayment.33": "Patient's Employer",
    "ePayment.34": "Patient's Employer's Address",
    "ePayment.35": "Patient's Employer's City",
    "ePayment.36": "Patient's Employer's State",
    "ePayment.37": "Patient's Employer's ZIP Code",
    "ePayment.38": "Patient's Employer's Country",
    "ePayment.39": "Patient's Employer's Primary Phone Number",
    "ePayment.40": "Response Urgency",
    "ePayment.41": "Patient Transport Assessment",
    "ePayment.42": "Specialty Care Transport Care Provider",
    "ePayment.44": "Ambulance Transport Reason Code",
    "ePayment.45": "Round Trip Purpose Description",
    "ePayment.46": "Stretcher Purpose Description",
    "ePayment.47": "Ambulance Conditions Indicator",
    "ePayment.48": "Mileage to Closest Hospital Facility",
    "ePayment.49": "ALS Assessment Performed and Warranted",
    "ePayment.50": "CMS Service Level",
    "ePayment.51": "EMS Condition Code",
    "ePayment.52": "CMS Transportation Indicator",
    "ePayment.53": "Transport Authorization Code",
    "ePayment.54": "Prior Authorization Code Payer",
    "ePayment.55": "Supply Item Used Name",
    "ePayment.56": "Number of Supply Item(s) Used",
    "ePayment.57": "Payer Type",
    "eScene.01": "First EMS Unit on Scene",
    "eScene.02": "Other EMS or Public Safety Agencies at Scene",
    "eScene.03": "Other EMS or Public Safety Agency ID Number",
    "eScene.04": "Type of Other Service at Scene",
    "eScene.24": "First Other EMS or Public Safety Agency at Scene to Provide Patient Care",
    "eScene.05": "Date/Time Initial Responder Arrived on Scene",
    "eScene.06": "Number of Patients at Scene",
    "eScene.07": "Mass Casualty Incident",
    "eScene.08": "Triage Classification for MCI Patient",
    "eScene.09": "Incident Location Type",
    "eScene.10": "Incident Facility Code",
    "eScene.11": "Scene GPS Location",
    "eScene.12": "Scene US National Grid Coordinates",
    "eScene.13": "Incident Facility or Location Name",
    "eScene.14": "Mile Post or Major Roadway",
    "eScene.15": "Incident Street Address",
    "eScene.16": "Incident Apartment, Suite, or Room",
    "eScene.17": "Incident City",
    "eScene.18": "Incident State",
    "eScene.19": "Incident ZIP Code",
    "eScene.20": "Scene Cross Street or Directions",
    "eScene.21": "Incident County",
    "eScene.22": "Incident Country",
    "eScene.23": "Incident Census Tract",
    "eSituation.01": "Date/Time of Symptom Onset",
    "eSituation.02": "Possible Injury",
    "eSituation.03": "Complaint Type",
    "eSituation.04": "Complaint",
    "eSituation.05": "Duration of Complaint",
    "eSituation.06": "Time Units of Duration of Complaint",
    "eSituation.07": "Chief Complaint Anatomic Location",
    "eSituation.08": "Chief Complaint Organ System",
    "eSituation.09": "Primary Symptom",
    "eSituation.10": "Other Associated Symptoms",
    "eSituation.11": "Provider's Primary Impression",
    "eSituation.12": "Provider's Secondary Impressions",
    "eSituation.13": "Initial Patient Acuity",
    "eSituation.14": "Work-Related Illness/Injury",
    "eSituation.15": "Patient's Occupational Industry",
    "eSituation.16": "Patient's Occupation",
    "eSituation.17": "Patient Activity",
    "eSituation.18": "Date/Time Last Known Well",
    "eSituation.19": "Justification for Transfer or Encounter",
    "eSituation.20": "Reason for Interfacility Transfer/Medical Transport",
    "eInjury.01": "Cause of Injury",
    "eInjury.02": "Mechanism of Injury",
    "eInjury.03": "Trauma Triage Criteria (High Risk for Serious Injury)",
    "eInjury.04": "Trauma Triage Criteria (Moderate Risk for Serious Injury)",
    "eInjury.05": "Main Area of the Vehicle Impacted by the Collision",
    "eInjury.06": "Location of Patient in Vehicle",
    "eInjury.07": "Use of Occupant Safety Equipment",
    "eInjury.08": "Airbag Deployment",
    "eInjury.09": "Height of Fall (feet)",
    "eInjury.10": "OSHA Personal Protective Equipment Used",
    "eInjury.11": "ACN System/Company Providing ACN Data",
    "eInjury.12": "ACN Incident ID",
    "eInjury.13": "ACN Call Back Phone Number",
    "eInjury.14": "Date/Time of ACN Incident",
    "eInjury.15": "ACN Incident Location",
    "eInjury.16": "ACN Incident Vehicle Body Type",
    "eInjury.17": "ACN Incident Vehicle Manufacturer",
    "eInjury.18": "ACN Incident Vehicle Make",
    "eInjury.19": "ACN Incident Vehicle Model",
    "eInjury.20": "ACN Incident Vehicle Model Year",
    "eInjury.21": "ACN Incident Multiple Impacts",
    "eInjury.22": "ACN Incident Delta Velocity",
    "eInjury.23": "ACN High Probability of Injury",
    "eInjury.24": "ACN Incident PDOF",
    "eInjury.25": "ACN Incident Rollover",
    "eInjury.26": "ACN Vehicle Seat Location",
    "eInjury.27": "Seat Occupied",
    "eInjury.28": "ACN Incident Seatbelt Use",
    "eInjury.29": "ACN Incident Airbag Deployed",
    "eArrest.01": "Cardiac Arrest",
    "eArrest.02": "Cardiac Arrest Etiology",
    "eArrest.03": "Resuscitation Attempted By EMS",
    "eArrest.04": "Arrest Witnessed By",
    "eArrest.07": "AED Use Prior to EMS Arrival",
    "eArrest.09": "Type of CPR Provided",
    "eArrest.10": "Therapeutic Hypothermia by EMS",
    "eArrest.11": "First Monitored Arrest Rhythm of the Patient",
    "eArrest.12": "Any Return of Spontaneous Circulation",
    "eArrest.13": "Neurological Outcome at Hospital Discharge",
    "eArrest.14": "Date/Time of Cardiac Arrest",
    "eArrest.15": "Date/Time Resuscitation Discontinued",
    "eArrest.16": "Reason CPR/Resuscitation Discontinued",
    "eArrest.17": "Cardiac Rhythm on Arrival at Destination",
    "eArrest.18": "End of EMS Cardiac Arrest Event",
    "eArrest.19": "Date/Time of Initial CPR",
    "eArrest.20": "Who First Initiated CPR",
    "eArrest.21": "Who First Applied the AED",
    "eArrest.22": "Who First Defibrillated the Patient",
    "eHistory.01": "Barriers to Patient Care",
    "eHistory.02": "Last Name of Patient's Practitioner",
    "eHistory.03": "First Name of Patient's Practitioner",
    "eHistory.04": "Middle Name/Initial of Patient's Practitioner",
    "eHistory.05": "Advance Directives",
    "eHistory.06": "Medication Allergies",
    "eHistory.07": "Environmental/Food Allergies",
    "eHistory.08": "Medical/Surgical History",
    "eHistory.09": "Medical History Obtained From",
    "eHistory.10": "The Patient's Type of Immunization",
    "eHistory.11": "Immunization Year",
    "eHistory.12": "Current Medications",
    "eHistory.13": "Current Medication Dose",
    "eHistory.14": "Current Medication Dosage Unit",
    "eHistory.15": "Current Medication Administration Route",
    "eHistory.20": "Current Medication Frequency",
    "eHistory.16": "Presence of Emergency Information Form",
    "eHistory.17": "Alcohol/Drug Use Indicators",
    "eHistory.18": "Pregnancy",
    "eHistory.19": "Last Oral Intake",
    "eNarrative.01": "Patient Care Report Narrative",
    "eVitals.01": "Date/Time Vital Signs Taken",
    "eVitals.02": "Obtained Prior to this Unit's EMS Care",
    "eVitals.03": "Cardiac Rhythm / Electrocardiography (ECG)",
    "eVitals.04": "ECG Type",
    "eVitals.05": "Method of ECG Interpretation",
    "eVitals.06": "SBP (Systolic Blood Pressure)",
    "eVitals.07": "DBP (Diastolic Blood Pressure)",
    "eVitals.08": "Method of Blood Pressure Measurement",
    "eVitals.09": "Mean Arterial Pressure",
    "eVitals.10": "Heart Rate",
    "eVitals.11": "Method of Heart Rate Measurement",
    "eVitals.12": "Pulse Oximetry",
    "eVitals.13": "Pulse Rhythm",
    "eVitals.14": "Respiratory Rate",
    "eVitals.15": "Respiratory Effort",
    "eVitals.16": "End Tidal Carbon Dioxide (ETCO2)",
    "eVitals.17": "Carbon Monoxide (CO)",
    "eVitals.18": "Blood Glucose Level",
    "eVitals.19": "Glasgow Coma Score-Eye",
    "eVitals.20": "Glasgow Coma Score-Verbal",
    "eVitals.21": "Glasgow Coma Score-Motor",
    "eVitals.22": "Glasgow Coma Score-Qualifier",
    "eVitals.23": "Total Glasgow Coma Score",
    "eVitals.24": "Temperature",
    "eVitals.25": "Temperature Method",
    "eVitals.26": "Level of Responsiveness (AVPU)",
    "eVitals.27": "Pain Scale Score",
    "eVitals.28": "Pain Scale Type",
    "eVitals.29": "Stroke Scale Score",
    "eVitals.30": "Stroke Scale Type",
    "eVitals.31": "Reperfusion Checklist",
    "eVitals.32": "APGAR",
    "eVitals.33": "Revised Trauma Score",
    "eLabs.01": "Date/Time of Laboratory or Imaging Result",
    "eLabs.02": "Study/Result Prior to this Unit's EMS Care",
    "eLabs.03": "Laboratory Result Type",
    "eLabs.04": "Laboratory Result",
    "eLabs.05": "Imaging Study Type",
    "eLabs.06": "Imaging Study Results",
    "eLabs.07": "Imaging Study File or Waveform Graphic Type",
    "eLabs.08": "Imaging Study File or Waveform Graphic",
    "eExam.01": "Estimated Body Weight in Kilograms",
    "eExam.02": "Length Based Tape Measure",
    "eExam.03": "Date/Time of Assessment",
    "eExam.04": "Skin Assessment",
    "eExam.05": "Head Assessment",
    "eExam.06": "Face Assessment",
    "eExam.07": "Neck Assessment",
    "eExam.09": "Heart Assessment",
    "eExam.10": "Abdominal Assessment Finding Location",
    "eExam.11": "Abdomen Assessment",
    "eExam.12": "Pelvis/Genitourinary Assessment",
    "eExam.13": "Back and Spine Assessment Finding Location",
    "eExam.14": "Back and Spine Assessment",
    "eExam.15": "Extremity Assessment Finding Location",
    "eExam.16": "Extremities Assessment",
    "eExam.17": "Eye Assessment Finding Location",
    "eExam.18": "Eye Assessment",
    "eExam.22": "Lung Assessment Finding Location",
    "eExam.23": "Lung Assessment",
    "eExam.24": "Chest Assessment Finding Location",
    "eExam.25": "Chest Assessment",
    "eExam.19": "Mental Status Assessment",
    "eExam.20": "Neurological Assessment",
    "eExam.21": "Stroke/CVA Symptoms Resolved",
    "eProtocols.01": "Protocols Used",
    "eProtocols.02": "Protocol Age Category",
    "eMedications.01": "Date/Time Medication Administered",
    "eMedications.02": "Medication Administered Prior to this Unit's EMS Care",
    "eMedications.03": "Medication Administered",
    "eMedications.04": "Medication Administered Route",
    "eMedications.05": "Medication Dosage",
    "eMedications.06": "Medication Dosage Units",
    "eMedications.07": "Response to Medication",
    "eMedications.08": "Medication Complication",
    "eMedications.09": "Medication Crew (Healthcare Professionals) ID",
    "eMedications.10": "Role/Type of Person Administering Medication",
    "eMedications.11": "Medication Authorization",
    "eMedications.12": "Medication Authorizing Physician",
    "eProcedures.01": "Date/Time Procedure Performed",
    "eProcedures.02": "Procedure Performed Prior to this Unit's EMS Care",
    "eProcedures.03": "Procedure",
    "eProcedures.04": "Size of Procedure Equipment",
    "eProcedures.05": "Number of Procedure Attempts",
    "eProcedures.06": "Procedure Successful",
    "eProcedures.07": "Procedure Complication",
    "eProcedures.08": "Response to Procedure",
    "eProcedures.09": "Procedure Crew Members ID",
    "eProcedures.10": "Role/Type of Person Performing the Procedure",
    "eProcedures.11": "Procedure Authorization",
    "eProcedures.12": "Procedure Authorizing Physician",
    "eProcedures.13": "Vascular Access Location",
    "eAirway.01": "Indications for Invasive Airway",
    "eAirway.02": "Date/Time Airway Device Placement Confirmation",
    "eAirway.03": "Airway Device Being Confirmed",
    "eAirway.04": "Airway Device Placement Confirmed Method",
    "eAirway.05": "Tube Depth",
    "eAirway.06": "Type of Individual Confirming Airway Device Placement",
    "eAirway.07": "Crew Member ID",
    "eAirway.08": "Airway Complications Encountered",
    "eAirway.09": "Suspected Reasons for Failed Airway Management",
    "eAirway.10": "Date/Time Decision to Manage the Patient with an Invasive Airway",
    "eAirway.11": "Date/Time Invasive Airway Placement Attempts Abandoned",
    "eDevice.01": "Medical Device Serial Number",
    "eDevice.02": "Date/Time of Event (per Medical Device)",
    "eDevice.03": "Medical Device Event Type",
    "eDevice.04": "Medical Device Waveform Graphic Type",
    "eDevice.05": "Medical Device Waveform Graphic",
    "eDevice.06": "Medical Device Mode (Manual, AED, Pacing, CO2, O2, etc)",
    "eDevice.07": "Medical Device ECG Lead",
    "eDevice.08": "Medical Device ECG Interpretation",
    "eDevice.09": "Type of Shock",
    "eDevice.10": "Shock or Pacing Energy",
    "eDevice.11": "Total Number of Shocks Delivered",
    "eDevice.12": "Pacing Rate",
    "eDisposition.01": "Destination/Transferred To, Name",
    "eDisposition.02": "Destination/Transferred To, Code",
    "eDisposition.03": "Destination Street Address",
    "eDisposition.04": "Destination City",
    "eDisposition.05": "Destination State",
    "eDisposition.06": "Destination County",
    "eDisposition.07": "Destination ZIP Code",
    "eDisposition.08": "Destination Country",
    "eDisposition.09": "Destination GPS Location",
    "eDisposition.10": "Destination Location US National Grid Coordinates",
    "eDisposition.11": "Number of Patients Transported in this EMS Unit",
    "eDisposition.27": "Unit Disposition",
    "eDisposition.28": "Patient Evaluation/Care",
    "eDisposition.29": "Crew Disposition",
    "eDisposition.30": "Transport Disposition",
    "eDisposition.31": "Reason for Refusal/Release",
    "eDisposition.13": "How Patient Was Moved to Ambulance",
    "eDisposition.14": "Position of Patient During Transport",
    "eDisposition.15": "How Patient Was Moved From Ambulance",
    "eDisposition.16": "EMS Transport Method",
    "eDisposition.17": "Transport Mode from Scene",
    "eDisposition.18": "Additional Transport Mode Descriptors",
    "eDisposition.19": "Final Patient Acuity",
    "eDisposition.20": "Reason for Choosing Destination",
    "eDisposition.21": "Type of Destination",
    "eDisposition.22": "Hospital In-Patient Destination",
    "eDisposition.23": "Hospital Capability",
    "eDisposition.24": "Destination Team Pre-Arrival Alert or Activation",
    "eDisposition.25": "Date/Time of Destination Prearrival Alert or Activation",
    "eDisposition.26": "Disposition Instructions Provided",
    "eDisposition.32": "Level of Care Provided per Protocol",
    "eOutcome.01": "Emergency Department Disposition",
    "eOutcome.02": "Hospital Disposition",
    "eOutcome.03": "External Report ID/Number Type",
    "eOutcome.04": "External Report ID/Number",
    "eOutcome.05": "Other Report Registry Type",
    "eOutcome.09": "Emergency Department Procedures",
    "eOutcome.19": "Date/Time Emergency Department Procedure Performed",
    "eOutcome.10": "Emergency Department Diagnosis",
    "eOutcome.11": "Date/Time of Hospital Admission",
    "eOutcome.12": "Hospital Procedures",
    "eOutcome.20": "Date/Time Hospital Procedure Performed",
    "eOutcome.13": "Hospital Diagnosis",
    "eOutcome.16": "Date/Time of Hospital Discharge",
    "eOutcome.18": "Date/Time of Emergency Department Admission",
    "eCustomResults.01": "Custom Data Element Result",
    "eCustomResults.02": "Custom Element ID Referenced",
    "eCustomResults.03": "CorrelationID of PatientCareReport Element or Group",
    "eOther.01": "Review Requested",
    "eOther.02": "Potential System of Care/Specialty/Registry Patient",
    "eOther.03": "Personal Protective Equipment Used",
    "eOther.04": "EMS Professional (Crew Member) ID",
    "eOther.05": "Suspected EMS Work Related Exposure, Injury, or Death",
    "eOther.06": "The Type of Work-Related Injury, Death or Suspected Exposure",
    "eOther.07": "Natural, Suspected, Intentional, or Unintentional Disaster",
    "eOther.08": "Crew Member Completing this Report",
    "eOther.09": "External Electronic Document Type",
    "eOther.10": "File Attachment Type",
    "eOther.11": "File Attachment Image",
    "eOther.22": "File Attachment Name",
    "eOther.12": "Type of Person Signing",
    "eOther.13": "Signature Reason",
    "eOther.14": "Type Of Patient Representative",
    "eOther.15": "Signature Status",
    "eOther.16": "Signature File Name",
    "eOther.17": "Signature File Type",
    "eOther.18": "Signature Graphic",
    "eOther.19": "Date/Time of Signature",
    "eOther.20": "Signature Last Name",
    "eOther.21": "Signature First Name"
}

module.exports = {
    NEMSIS_KEY_CODES
}
