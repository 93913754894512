const NEMSIS_eCustomConfiguration_CODES = {
    // eCustomConfiguration.01 - Custom Data Element Title (no associated codes)
    
    // eCustomConfiguration.02 - Custom Definition (no associated codes)
    
    // eCustomConfiguration.03 - Custom Data Type
    9902001: "Binary",
    9902003: "Date/Time",
    9902005: "Integer/Number",
    9902007: "Other",
    9902009: "Text/String",
    9902011: "Boolean",
    
    // eCustomConfiguration.04 - Custom Data Element Recurrence
    9923001: "No",
    9923003: "Yes",

    // eCustomConfiguration.05 - Custom Data Element Usage
    9903001: "Mandatory",
    9903003: "Required",
    9903005: "Recommended",
    9903007: "Optional",
    
    // eCustomConfiguration.06 - Custom Data Element Potential Values (no associated codes)
    
    // eCustomConfiguration.07 - Custom Data Element Potential NOT Values
    7701001: "Not Applicable",
    7701003: "Not Recorded",
    7701005: "Not Reporting",
    
    // eCustomConfiguration.08 - Custom Data Element Potential Pertinent Negative Values
    8801001: "Contraindication Noted",
    8801003: "Denied By Order",
    8801005: "Exam Finding Not Present",
    8801007: "Medication Allergy",
    8801009: "Medication Already Taken",
    8801013: "No Known Drug Allergy",
    8801015: "None Reported",
    8801017: "Not Performed by EMS",
    8801019: "Refused",
    8801021: "Unresponsive",
    8801023: "Unable to Complete",
    8801025: "Not Immunized",
    8801027: "Order Criteria Not Met",
    8801029: "Approximate",
    8801031: "Symptom Not Present",
    
    // eCustomConfiguration.09 - Custom Data Element Grouping ID (no associated codes)
};

const NEMSIS_eRecord_CODES = {
    // eRecord.01 - Patient Care Report Number (no associated codes)
    
    // eRecord.02 - Software Creator (no associated codes)
    
    // eRecord.03 - Software Name (no associated codes)
    
    // eRecord.04 - Software Version (no associated codes)
};

const NEMSIS_eResponse_CODES = {
    // eResponse.01 - EMS Agency Number (no associated codes)
    
    // eResponse.02 - EMS Agency Name (no associated codes)
    
    // eResponse.03 - Incident Number (no associated codes)
    
    // eResponse.04 - EMS Response Number (no associated codes)
    
    // eResponse.05 - Type of Service Requested
    2205001: "Emergency Response (Primary Response Area)",
    2205003: "Emergency Response (Intercept)",
    2205009: "Emergency Response (Mutual Aid)",
    2205005: "Hospital-to-Hospital Transfer",
    2205015: "Hospital to Non-Hospital Facility Transfer",
    2205017: "Non-Hospital Facility to Non-Hospital Facility Transfer",
    2205019: "Non-Hospital Facility to Hospital Transfer",
    2205007: "Other Routine Medical Transport",
    2205011: "Public Assistance",
    2205013: "Standby",
    2205021: "Support Services",
    2205023: "Non-Patient Care Rescue/Extrication",
    2205025: "Crew Transport Only",
    2205027: "Transport of Organs or Body Parts",
    2205029: "Mortuary Services",
    2205031: "Mobile Integrated Health Care Encounter",
    2205033: "Evaluation for Special Referral/Intake Programs",
    2205035: "Administrative Operations",

    // eResponse.06 - Standby Purpose
    2206001: "Disaster Event-Drill/Exercise",
    2206003: "Disaster Event-Live Staging",
    2206005: "Education",
    2206007: "EMS Staging-Improve Coverage",
    2206009: "Fire Support-Rehab",
    2206011: "Fire Support-Standby",
    2206013: "Mass Gathering-Concert/Entertainment Event",
    2206015: "Mass Gathering-Fair/Community Event",
    2206017: "Mass Gathering-Sporting Event",
    2206019: "Other",
    2206021: "Public Safety Support",

    // eResponse.07 - Unit Transport and Equipment Capability
    2207011: "Air Transport-Helicopter",
    2207013: "Air Transport-Fixed Wing",
    2207015: "Ground Transport (ALS Equipped)",
    2207017: "Ground Transport (BLS Equipped)",
    2207019: "Ground Transport (Critical Care Equipped)",
    2207021: "Non-Transport-Medical Treatment (ALS Equipped)",
    2207023: "Non-Transport-Medical Treatment (BLS Equipped)",
    2207025: "Wheel Chair Van/Ambulette",
    2207027: "Non-Transport-No Medical Equipment",

    // eResponse.08 - Type of Dispatch Delay
    2208001: "Caller (Uncooperative)",
    2208003: "Diversion/Failure (of previous unit)",
    2208005: "High Call Volume",
    2208007: "Language Barrier",
    2208009: "Incomplete Address Information Provided",
    2208011: "No EMS Vehicles (Units) Available",
    2208013: "None/No Delay",
    2208015: "Other",
    2208017: "Technical Failure (Computer, Phone etc.)",
    2208019: "Communication Specialist-Assignment Error",
    2208021: "No Receiving MD, Bed, Hospital",
    2208023: "Specialty Team Delay",

    // eResponse.09 - Type of Response Delay
    2209001: "Crowd",
    2209003: "Directions/Unable to Locate",
    2209005: "Distance",
    2209007: "Diversion (Different Incident)",
    2209009: "HazMat",
    2209011: "None/No Delay",
    2209013: "Other",
    2209015: "Rendezvous Transport Unavailable",
    2209017: "Route Obstruction (e.g., Train)",
    2209019: "Scene Safety (Not Secure for EMS)",
    2209021: "Staff Delay",
    2209023: "Traffic",
    2209025: "Vehicle Crash Involving this Unit",
    2209027: "Vehicle Failure of this Unit",
    2209029: "Weather",
    2209031: "Mechanical Issue-Unit, Equipment, etc.",
    2209033: "Flight Planning",

    // eResponse.10 - Type of Scene Delay
    2210001: "Awaiting Air Unit",
    2210003: "Awaiting Ground Unit",
    2210005: "Crowd",
    2210007: "Directions/Unable to Locate",
    2210009: "Distance",
    2210011: "Extrication",
    2210013: "HazMat",
    2210015: "Language Barrier",
    2210017: "None/No Delay",
    2210019: "Other",
    2210021: "Patient Access",
    2210023: "Safety-Crew/Staging",
    2210025: "Safety-Patient",
    2210027: "Staff Delay",
    2210029: "Traffic",
    2210031: "Triage/Multiple Patients",
    2210033: "Vehicle Crash Involving this Unit",
    2210035: "Vehicle Failure of this Unit",
    2210037: "Weather",
    2210039: "Mechanical Issue-Unit, Equipment, etc.",

    // eResponse.11 - Type of Transport Delay
    2211001: "Crowd",
    2211003: "Directions/Unable to Locate",
    2211005: "Distance",
    2211007: "Diversion",
    2211009: "HazMat",
    2211011: "None/No Delay",
    2211013: "Other",
    2211015: "Rendezvous Transport Unavailable",
    2211017: "Route Obstruction (e.g., Train)",
    2211019: "Safety",
    2211021: "Staff Delay",
    2211023: "Traffic",
    2211025: "Vehicle Crash Involving this Unit",
    2211027: "Vehicle Failure of this Unit",
    2211029: "Weather",
    2211031: "Patient Condition Change (e.g., Unit Stopped)",

    // eResponse.12 - Type of Turn-Around Delay
    2212001: "Clean-up",
    2212003: "Decontamination",
    2212005: "Distance",
    2212007: "Documentation",
    2212009: "ED Overcrowding / Transfer of Care",
    2212011: "Equipment Failure",
    2212013: "Equipment/Supply Replenishment",
    2212015: "None/No Delay",
    2212017: "Other",
    2212019: "Rendezvous Transport Unavailable",
    2212021: "Route Obstruction (e.g., Train)",
    2212023: "Staff Delay",
    2212025: "Traffic",
    2212027: "Vehicle Crash of this Unit",
    2212029: "Vehicle Failure of this Unit",
    2212031: "Weather",
    2212033: "EMS Crew Accompanies Patient for Facility Procedure",

    // eResponse.13 - EMS Vehicle (Unit) Number (no associated codes)
    
    // eResponse.14 - EMS Unit Call Sign (no associated codes)
    
    // eResponse.16 - Vehicle Dispatch Location (no associated codes)
    
    // eResponse.17 - Vehicle Dispatch GPS Location (no associated codes)
    
    // eResponse.18 - Vehicle Dispatch Location US National Grid Coordinates (no associated codes)
    
    // eResponse.19 - Beginning Odometer Reading of Responding Vehicle (no associated codes)
    
    // eResponse.20 - On-Scene Odometer Reading of Responding Vehicle (no associated codes)
    
    // eResponse.21 - Patient Destination Odometer Reading of Responding Vehicle (no associated codes)
    
    // eResponse.22 - Ending Odometer Reading of Responding Vehicle (no associated codes)
    
    // eResponse.23 - Response Mode to Scene
    2223001: "Emergent (Immediate Response)",
    2223003: "Emergent Downgraded to Non-Emergent",
    2223005: "Non-Emergent",
    2223007: "Non-Emergent Upgraded to Emergent",
    
    // eResponse.24 - Additional Response Mode Descriptors
    2224001: "Intersection Navigation-Against Normal Light Patterns",
    2224003: "Intersection Navigation-With Automated Light Changing Technology",
    2224005: "Intersection Navigation-With Normal Light Patterns",
    2224007: "Scheduled",
    2224009: "Speed-Enhanced per Local Policy",
    2224011: "Speed-Normal Traffic",
    2224013: "Unscheduled",
    2224015: "Lights and Sirens",
    2224017: "Lights and No Sirens",
    2224019: "No Lights or Sirens",
    2224021: "Initial No Lights or Sirens, Upgraded to Lights and Sirens",
    2224023: "Initial Lights and Sirens, Downgraded to No Lights or Sirens"
};

const NEMSIS_eDispatch_CODES = {
    // eDispatch.01 - Dispatch Reason
    2301001: "Abdominal Pain/Problems",
    2301003: "Allergic Reaction/Stings",
    2301005: "Animal Bite",
    2301007: "Assault",
    2301009: "Automated Crash Notification",
    2301011: "Back Pain (Non-Traumatic)",
    2301013: "Breathing Problem",
    2301015: "Burns/Explosion",
    2301017: "Carbon Monoxide/Hazmat/Inhalation/CBRN",
    2301019: "Cardiac Arrest/Death",
    2301021: "Chest Pain (Non-Traumatic)",
    2301023: "Choking",
    2301025: "Convulsions/Seizure",
    2301027: "Diabetic Problem",
    2301029: "Electrocution/Lightning",
    2301031: "Eye Problem/Injury",
    2301033: "Falls",
    2301035: "Fire",
    2301037: "Headache",
    2301039: "Healthcare Professional/Admission",
    2301041: "Heart Problems/AICD",
    2301043: "Heat/Cold Exposure",
    2301045: "Hemorrhage/Laceration",
    2301047: "Industrial Accident/Inaccessible Incident/Other Entrapments (Non-Vehicle)",
    2301049: "Medical Alarm",
    2301051: "No Other Appropriate Choice",
    2301053: "Overdose/Poisoning/Ingestion",
    2301055: "Pandemic/Epidemic/Outbreak",
    2301057: "Pregnancy/Childbirth/Miscarriage",
    2301059: "Psychiatric Problem/Abnormal Behavior/Suicide Attempt",
    2301061: "Sick Person",
    2301063: "Stab/Gunshot Wound/Penetrating Trauma",
    2301065: "Standby",
    2301067: "Stroke/CVA",
    2301069: "Traffic/Transportation Incident",
    2301071: "Transfer/Interfacility/Palliative Care",
    2301073: "Traumatic Injury",
    2301075: "Well Person Check",
    2301077: "Unconscious/Fainting/Near-Fainting",
    2301079: "Unknown Problem/Person Down",
    2301081: "Drowning/Diving/SCUBA Accident",
    2301083: "Airmedical Transport",
    2301085: "Altered Mental Status",
    2301087: "Intercept",
    2301089: "Nausea",
    2301091: "Vomiting",

    // eDispatch.02 - EMD Performed
    2302001: "No",
    2302003: "Yes, With Pre-Arrival Instructions",
    2302005: "Yes, Without Pre-Arrival Instructions",
    2302007: "Yes, Unknown if Pre-Arrival Instructions Given",

    // eDispatch.05 - Dispatch Priority (Patient Acuity)
    2305001: "Critical",
    2305003: "Emergent",
    2305005: "Lower Acuity",
    2305007: "Non-Acute [e.g., Scheduled Transfer or Standby]"
};

const NEMSIS_eCrew_CODES = {
    // eCrew.01 - Crew Member ID (no associated codes)
    
    // eCrew.02 - Crew Member Level
    9925001: "Advanced Emergency Medical Technician (AEMT)",
    9925002: "Emergency Medical Technician - Intermediate",
    9925003: "Emergency Medical Responder (EMR)", 
    9925005: "Emergency Medical Technician (EMT)",
    9925007: "Paramedic",
    9925023: "Other Healthcare Professional",
    9925025: "Other Non-Healthcare Professional",
    9925027: "Physician",
    9925029: "Respiratory Therapist", 
    9925031: "Student",
    9925033: "Critical Care Paramedic",
    9925035: "Community Paramedicine",
    9925037: "Nurse Practitioner",
    9925039: "Physician Assistant",
    9925041: "Licensed Practical Nurse (LPN)",
    9925043: "Registered Nurse",

    // eCrew.03 - Crew Member Response Role
    2403001: "Driver/Pilot-Response",
    2403003: "Driver/Pilot-Transport",
    2403005: "Other",
    2403007: "Other Patient Caregiver-At Scene",
    2403009: "Other Patient Caregiver-Transport",
    2403011: "Primary Patient Caregiver-At Scene",
    2403013: "Primary Patient Caregiver-Transport"
};

const NEMSIS_eTimes_CODES = {
    // no codes
}

const NEMSIS_ePatient_CODES = {
    // ePatient.01 - EMS Patient ID (no associated codes)
    
    // ePatient.02 - Last Name (no associated codes)
    
    // ePatient.03 - First Name (no associated codes)
    
    // ePatient.04 - Middle Initial/Name (no associated codes)
    
    // ePatient.05 - Patient's Home Address (no associated codes)
    
    // ePatient.06 - Patient's Home City (no associated codes)
    
    // ePatient.07 - Patient's Home County (no associated codes)
    
    // ePatient.08 - Patient's Home State (no associated codes)
    
    // ePatient.09 - Patient's Home ZIP Code (no associated codes)
    
    // ePatient.10 - Patient's Country of Residence (no associated codes)
    
    // ePatient.11 - Patient Home Census Tract (no associated codes)
    
    // ePatient.12 - Social Security Number (no associated codes)
    
    // ePatient.13 - Gender
    9906001: "Female",
    9906003: "Male",
    9906007: "Female-to-Male, Transgender Male",
    9906009: "Male-to-Female, Transgender Female",
    9906011: "Other, neither exclusively male or female",
    9906005: "Unknown (Unable to Determine)",
    
    // ePatient.14 - Race
    2514001: "American Indian or Alaska Native",
    2514003: "Asian",
    2514005: "Black or African American",
    2514007: "Hispanic or Latino",
    2514009: "Native Hawaiian or Other Pacific Islander",
    2514011: "White",
    
    // ePatient.15 - Age (no associated codes)
    
    // ePatient.16 - Age Units
    2516001: "Days",
    2516003: "Hours",
    2516005: "Minutes",
    2516007: "Months",
    2516009: "Years",
    
    // ePatient.17 - Date of Birth (no associated codes)
    
    // ePatient.18 - Patient's Phone Number (no associated codes)
    
    // ePatient.19 - Patient's Email Address (no associated codes)
    
    // ePatient.20 - State Issuing Driver's License (no associated codes)
    
    // ePatient.21 - Driver's License Number (no associated codes)
    
    // ePatient.22 - Alternate Home Residence
    2522001: "Homeless",
    2522003: "Migrant Worker",
    2522005: "Foreign Visitor"
};

const NEMSIS_ePayment_CODES = {
    // ePayment.40 - Response Urgency
    2640001: "Immediate",
    2640003: "Non-Immediate"
}

const NEMSIS_eScene_CODES = {
    // eScene.01 - First EMS Unit on Scene
    9923001: "No",
    9923003: "Yes",

    // eScene.02 - Other EMS or Public Safety Agencies at Scene (no associated codes)
    
    // eScene.03 - Other EMS or Public Safety Agency ID Number (no associated codes)

    // eScene.04 - Type of Other Service at Scene
    2704001: "EMS Mutual Aid",
    2704003: "Fire",
    2704005: "First Responder",
    2704007: "Hazmat",
    2704009: "Law",
    2704011: "Other",
    2704013: "Other EMS Agency",
    2704015: "Other Health Care Provider",
    2704017: "Rescue",
    2704019: "Utilities",

    // eScene.05 - Date/Time Initial Responder Arrived on Scene (no associated codes)

    // eScene.06 - Number of Patients at Scene
    2707001: "Multiple",
    2707003: "None",
    2707005: "Single",

    // eScene.07 - Mass Casualty Incident
    9923001: "No",
    9923003: "Yes",

    // eScene.08 - Triage Classification for MCI Patient
    2708001: "Red - Immediate",
    2708003: "Yellow - Delayed",
    2708005: "Green - Minimal (Minor)",
    2708007: "Gray - Expectant",
    2708009: "Black - Deceased",

    // eScene.09 - Incident Location Type (no associated codes)
    
    // eScene.10 - Incident Facility Code (no associated codes)
    
    // eScene.11 - Scene GPS Location (no associated codes)
    
    // eScene.12 - Scene US National Grid Coordinates (no associated codes)
    
    // eScene.13 - Incident Facility or Location Name (no associated codes)
    
    // eScene.14 - Mile Post or Major Roadway (no associated codes)
    
    // eScene.15 - Incident Street Address (no associated codes)
    
    // eScene.16 - Incident Apartment, Suite, or Room (no associated codes)
    
    // eScene.17 - Incident City (no associated codes)
    
    // eScene.18 - Incident State (no associated codes)
    
    // eScene.19 - Incident ZIP Code (no associated codes)
    
    // eScene.20 - Scene Cross Street or Directions (no associated codes)
    
    // eScene.21 - Incident County (no associated codes)
    
    // eScene.22 - Incident Country (no associated codes)
    
    // eScene.23 - Incident Census Tract (no associated codes)
    
    // eScene.24 - First Other EMS or Public Safety Agency at Scene to Provide Patient Care
    9923001: "No",
    9923003: "Yes"
};

const NEMSIS_eSituation_CODES = {
    // eSituation.01 - Date/Time of Symptom Onset (no associated codes)
    
    // eSituation.02 - Possible Injury
    9922001: "No",
    9922003: "Unknown",
    9922005: "Yes",

    // eSituation.03 - Complaint Type
    2803001: "Chief (Primary)",
    2803003: "Other",
    2803005: "Secondary",
    
    // eSituation.04 - Complaint (no associated codes)
    
    // eSituation.05 - Duration of Complaint (no associated codes)
    
    // eSituation.06 - Time Units of Duration of Complaint
    2806001: "Seconds",
    2806003: "Minutes",
    2806005: "Hours",
    2806007: "Days",
    2806009: "Weeks",
    2806011: "Months",
    2806013: "Years",
    
    // eSituation.07 - Chief Complaint Anatomic Location
    2807001: "Abdomen",
    2807003: "Back",
    2807005: "Chest",
    2807007: "Extremity-Lower",
    2807009: "Extremity-Upper",
    2807011: "General/Global",
    2807013: "Genitalia",
    2807015: "Head",
    2807017: "Neck",

    // eSituation.08 - Chief Complaint Organ System
    2808001: "Behavioral/Psychiatric",
    2808003: "Cardiovascular",
    2808005: "CNS/Neuro",
    2808007: "Endocrine/Metabolic",
    2808009: "GI",
    2808011: "Global/General",
    2808013: "Lymphatic/Immune",
    2808015: "Musculoskeletal/Skin",
    2808017: "Reproductive",
    2808019: "Pulmonary",
    2808021: "Renal",
    
    // eSituation.09 - Primary Symptom (no associated codes)
    
    // eSituation.10 - Other Associated Symptoms (no associated codes)
    
    // eSituation.11 - Provider's Primary Impression (no associated codes)
    
    // eSituation.12 - Provider's Secondary Impressions (no associated codes)
    
    // eSituation.13 - Initial Patient Acuity
    2813001: "Critical (Red)",
    2813003: "Emergent (Yellow)",
    2813005: "Lower Acuity (Green)",
    2813007: "Dead without Resuscitation Efforts (Black)",
    2813009: "Non-Acute/Routine",
    
    // eSituation.14 - Work-Related Illness/Injury
    9922001: "No",
    9922003: "Unknown",
    9922005: "Yes",
    
    // eSituation.15 - Patient's Occupational Industry
    2815001: "Accommodation and Food Services",
    2815003: "Administrative and Support and Waste Management and Remediation Services",
    2815005: "Agriculture, Forestry, Fishing and Hunting",
    2815007: "Arts, Entertainment, and Recreation",
    2815009: "Construction",
    2815011: "Educational Services",
    2815013: "Finance and Insurance",
    2815015: "Health Care and Social Assistance",
    2815017: "Information",
    2815019: "Management of Companies and Enterprises",
    2815021: "Manufacturing",
    2815023: "Mining, Quarrying, and Oil and Gas Extraction",
    2815025: "Other Services (except Public Administration)",
    2815027: "Professional, Scientific, and Technical Services",
    2815029: "Public Administration",
    2815031: "Real Estate and Rental and Leasing",
    2815033: "Retail Trade",
    2815035: "Transportation and Warehousing",
    2815037: "Utilities",
    2815039: "Wholesale Trade",

    // eSituation.16 - Patient's Occupation
    2816001: "Architecture and Engineering Occupations",
    2816003: "Arts, Design, Entertainment, Sports, and Media Occupations",
    2816005: "Building and Grounds Cleaning and Maintenance Occupations",
    2816007: "Business and Financial Operations Occupations",
    2816009: "Community and Social Services Occupations",
    2816011: "Computer and Mathematical Occupations",
    2816013: "Construction and Extraction Occupations",
    2816015: "Educational Instruction and Library Occupations",
    2816017: "Farming, Fishing and Forestry Occupations",
    2816019: "Food Preparation and Serving Related Occupations",
    2816021: "Healthcare Practitioners and Technical Occupations",
    2816023: "Healthcare Support Occupations",
    2816025: "Installation, Maintenance, and Repair Occupations",
    2816027: "Legal Occupations",
    2816029: "Life, Physical, and Social Science Occupations",
    2816031: "Management Occupations",
    2816033: "Military Specific Occupations",
    2816035: "Office and Administrative Support Occupations",
    2816037: "Personal Care and Service Occupations",
    2816039: "Production Occupations",
    2816041: "Protective Service Occupations",
    2816043: "Sales and Related Occupations",
    2816045: "Transportation and Material Moving Occupations",
    
    // eSituation.17 - Patient Activity (no associated codes)
    
    // eSituation.18 - Date/Time Last Known Well (no associated codes)
    
    // eSituation.19 - Justification for Transfer or Encounter (no associated codes)
    
    // eSituation.20 - Reason for Interfacility Transfer/Medical Transport
    2820001: "Cardiac Specialty",
    2820003: "Convenience Transfer (Patient Request)",
    2820005: "Diagnostic Testing",
    2820007: "Dialysis",
    2820009: "Drug and/or Alcohol Rehabilitation Care",
    2820011: "Extended Care",
    2820013: "Maternal/Neonatal",
    2820015: "Medical Specialty Care (Other, Not Listed)",
    2820017: "Neurological Specialty Care",
    2820019: "Palliative/Hospice Care (Home or Facility)",
    2820021: "Pediatric Specialty Care",
    2820023: "Psychiatric/Behavioral Care",
    2820025: "Physical Rehabilitation Care",
    2820027: "Return to Home/Residence",
    2820029: "Surgical Specialty Care (Other, Not Listed)",
    2820031: "Trauma/Orthopedic Specialty Care"
};

const NEMSIS_eInjury_CODES = {
    // eInjury.01 - Cause of Injury (no associated codes)
    
    // eInjury.02 - Mechanism of Injury
    2902001: "Blunt",
    2902003: "Burn",
    2902005: "Other",
    2902007: "Penetrating",

    // eInjury.03 - Trauma Triage Criteria (High Risk for Serious Injury)
    2903001: "Amputation proximal to wrist or ankle",
    2903003: "Crushed, degloved, mangled, or pulseless extremity",
    2903005: "Chest wall instability, deformity, or suspected flail chest",
    2903007: "Glasgow Coma Score <= 13",
    2903009: "Skull deformity, suspected skull fracture",
    2903011: "Paralysis",
    2903013: "Suspected pelvic fracture",
    2903015: "Penetrating injuries to head, neck, torso, and proximal extremities",
    2903017: "Respiratory Rate <10 or >29 breaths per minute (<20 in infants aged <1 year) or need for ventilatory support",
    2903019: "Systolic Blood Pressure <90 mmHg",
    2903021: "Suspected fracture of two or more proximal long bones",
    2903023: "Active bleeding requiring a tourniquet or wound packing with continuous pressure",
    2903025: "Age >= 10 years: HR > SBP",
    2903027: "Age >= 65 years: SBP < 110 mmHg",
    2903029: "Age 0-9 years: SBP < 70mm Hg + (2 x age in years)",
    2903031: "Age 10-64 years: SBP < 90 mmHg",
    2903033: "Respiratory distress or need for respiratory support",
    2903035: "Room-air pulse oximetry < 90%",
    2903037: "RR < 10 or > 29 breaths/min",
    2903039: "Suspected spinal injury with new motor or sensory loss",
    2903041: "Unable to follow commands (motor GCS < 6)",

    // eInjury.04 - Trauma Triage Criteria (Moderate Risk for Serious Injury)
    2904001: "Pedestrian/bicycle rider thrown, run over, or with significant impact",
    2904003: "Fall Adults: > 20 ft. (one story is equal to 10 ft.)",
    2904005: "Fall Children: > 10 ft. or 2-3 times the height of the child",
    2904007: "Auto Crash: Death in passenger compartment",
    2904009: "Auto Crash: Partial or complete ejection",
    2904011: "Auto Crash: Significant intrusion (including roof): >12 inches occupant site; >18 inches any site; need for extrication",
    2904013: "Auto Crash: Vehicle telemetry data consistent with severe injury",
    2904015: "Motorcycle Crash > 20 MPH",
    2904017: "SBP < 110 for age > 65",
    2904019: "Anticoagulant use",
    2904021: "Pregnancy > 20 weeks",
    2904023: "Other EMS judgment",
    2904025: "Burn, without other trauma",
    2904027: "Burns in conjunction with trauma",
    2904029: "Auto Crash: Child (age 0-9 years) unrestrained or in unsecured child safety seat",
    2904031: "Fall from height > 10 feet (all ages)",
    2904033: "Low-level falls in young children (age <= 5 years) or older adults (age >= 65 years) with significant head impact",
    2904035: "Rider separated from transport vehicle with significant impact (eg, motorcycle, ATV, horse, etc.)",
    2904037: "Special, high-resource healthcare needs",
    2904039: "Suspicion of child abuse",

    // eInjury.05 - Main Area of the Vehicle Impacted by the Collision (no associated codes)
    
    // eInjury.06 - Location of Patient in Vehicle
    2906001: "Front Seat-Left Side (or motorcycle driver)",
    2906003: "Front Seat-Middle",
    2906005: "Front Seat-Right Side",
    2906007: "Passenger in other enclosed passenger or cargo area (non-trailing unit such as a bus, etc.)",
    2906009: "Passenger in unenclosed passenger or cargo area (non-trailing unit such as a pickup, etc.)",
    2906011: "Riding on Vehicle Exterior (non-trailing unit)",
    2906013: "Second Seat-Left Side (or motorcycle passenger)",
    2906015: "Second Seat-Middle",
    2906017: "Second Seat-Right Side",
    2906019: "Sleeper Section of Cab (truck)",
    2906021: "Third Row-Left Side (or motorcycle passenger)",
    2906023: "Third Row-Middle",
    2906025: "Third Row-Right Side",
    2906027: "Trailing Unit",
    2906029: "Unknown",

    // eInjury.07 - Use of Occupant Safety Equipment
    2907001: "Child Booster Seat",
    2907003: "Eye Protection",
    2907005: "Helmet Worn",
    2907007: "Infant Car Seat Forward Facing",
    2907009: "Infant Car Seat Rear Facing",
    2907015: "None",
    2907017: "Other",
    2907019: "Personal Floatation Device",
    2907021: "Protective Clothing",
    2907023: "Protective Non-Clothing Gear",
    2907027: "Shoulder and Lap Belt Used",
    2907029: "Lap Belt Only Used",
    2907031: "Shoulder Belt Only Used",
    2907033: "Unable to Determine",

    // eInjury.08 - Airbag Deployment
    2908001: "Airbag Deployed Front",
    2908003: "Airbag Deployed Side",
    2908005: "Airbag Deployed Other (knee, air belt, etc.)",
    2908007: "No Airbag Deployed",
    2908009: "No Airbag Present",

    // eInjury.09 - Height of Fall (feet) (no associated codes)
    
    // eInjury.10 - OSHA Personal Protective Equipment Used
    2910001: "Eye and Face Protection",
    2910003: "Foot Protection",
    2910005: "Head Protection",
    2910007: "Hearing Protection",
    2910009: "Respiratory Protection",
    2910011: "Safety Belts, lifelines, and lanyards",
    2910013: "Safety Nets",

    // eInjury.11 - ACN System/Company Providing ACN Data (no associated codes)
    
    // eInjury.12 - ACN Incident ID (no associated codes)
    
    // eInjury.13 - ACN Call Back Phone Number (no associated codes)
    
    // eInjury.14 - Date/Time of ACN Incident (no associated codes)
    
    // eInjury.15 - ACN Incident Location (no associated codes)
    
    // eInjury.16 - ACN Incident Vehicle Body Type (no associated codes)
    
    // eInjury.17 - ACN Incident Vehicle Manufacturer (no associated codes)
    
    // eInjury.18 - ACN Incident Vehicle Make (no associated codes)
    
    // eInjury.19 - ACN Incident Vehicle Model (no associated codes)
    
    // eInjury.20 - ACN Incident Vehicle Model Year (no associated codes)
    
    // eInjury.21 - ACN Incident Multiple Impacts
    9923001: "No",
    9923003: "Yes",

    // eInjury.22 - ACN Incident Delta Velocity (no associated codes)

    // eInjury.23 - ACN High Probability of Injury
    9923001: "No",
    9923003: "Yes",

    // eInjury.24 - ACN Incident PDOF (no associated codes)
    
    // eInjury.25 - ACN Incident Rollover
    "Y": "Yes",
    "N": "No",

    // eInjury.26 - ACN Vehicle Seat Location
    2926001: "Driver Front Seat",
    2926003: "Front Row Middle Seat",
    2926005: "Passenger Front Seat",
    2926007: "Second Row Left Seat",
    2926009: "Second Row Middle Seat",
    2926011: "Second Row Right Seat",
    2926013: "Third Row Left Seat",
    2926015: "Third Row Middle Seat",
    2926017: "Third Row Right Seat",

    // eInjury.27 - Seat Occupied
    "Y": "Yes",
    "N": "No",

    // eInjury.28 - ACN Incident Seatbelt Use
    "Y": "Yes",
    "N": "No",

    // eInjury.29 - ACN Incident Airbag Deployed
    "Y": "Yes",
    "N": "No"
};

const NEMSIS_eArrest_CODES = {
    // eArrest.01 - Cardiac Arrest
    3001001: "No",
    3001003: "Yes, Prior to Any EMS Arrival (includes Transport EMS & Medical First Responders)",
    3001005: "Yes, After Any EMS Arrival (includes Transport EMS & Medical First Responders)",

    // eArrest.02 - Cardiac Arrest Etiology
    3002001: "Cardiac (Presumed)",
    3002003: "Drowning/Submersion", 
    3002005: "Drug Overdose",
    3002007: "Electrocution",
    3002009: "Exsanguination-Medical (Non-Traumatic)",
    3002011: "Other",
    3002013: "Respiratory/Asphyxia",
    3002015: "Traumatic Cause",

    // eArrest.03 - Resuscitation Attempted By EMS
    3003001: "Attempted Defibrillation",
    3003003: "Attempted Ventilation",
    3003005: "Initiated Chest Compressions",
    3003007: "Not Attempted-Considered Futile",
    3003009: "Not Attempted-DNR Orders",
    3003011: "Not Attempted-Signs of Circulation",

    // eArrest.04 - Arrest Witnessed By
    3004001: "Not Witnessed",
    3004003: "Witnessed by Family Member",
    3004005: "Witnessed by Healthcare Provider",
    3004007: "Witnessed by Bystander",

    // eArrest.07 - AED Use Prior to EMS Arrival
    3007001: "No",
    3007003: "Yes, Applied without Defibrillation",
    3007005: "Yes, With Defibrillation",

    // eArrest.09 - Type of CPR Provided
    3009001: "Compressions-Manual",
    3009003: "Compressions-External Band Type Device",
    3009005: "Compressions-External Plunger Type Device",
    3009007: "Compressions-External Thumper Type Device",
    3009009: "Compressions-Intermittent with Ventilation",
    3009011: "Compressions-Other Device",
    3009021: "Compressions-Hands Only CPR",
    3009013: "Ventilation-Bag Valve Mask",
    3009015: "Ventilation-Impedance Threshold Device",
    3009017: "Ventilation-Mouth to Mouth",
    3009019: "Ventilation-Pocket Mask",
    3009023: "Ventilation-with OPA/NPA",
    3009025: "Ventilation-Advanced Airway Device",
    3009027: "Ventilation-Passive Ventilation with Oxygen",

    // eArrest.10 - Therapeutic Hypothermia by EMS
    9923001: "No",
    9923003: "Yes",

    // eArrest.11 - First Monitored Arrest Rhythm of the Patient
    3011001: "Asystole",
    3011005: "PEA",
    3011007: "Unknown AED Non-Shockable Rhythm",
    3011009: "Unknown AED Shockable Rhythm",
    3011011: "Ventricular Fibrillation",
    3011013: "Ventricular Tachycardia-Pulseless",

    // eArrest.12 - Any Return of Spontaneous Circulation
    3012001: "No",
    3012003: "Yes, At Arrival at the ED",
    3012005: "Yes, Prior to Arrival at the ED",
    3012007: "Yes, Sustained for 20 consecutive minutes",

    // eArrest.13 - Neurological Outcome at Hospital Discharge
    3013001: "CPC 1 Good Cerebral Performance",
    3013003: "CPC 2 Moderate Cerebral Disability",
    3013005: "CPC 3 Severe Cerebral Disability",
    3013007: "CPC 4 Coma or Vegetative State",

    // eArrest.16 - Reason CPR/Resuscitation Discontinued
    3016001: "DNR",
    3016003: "Medical Control Order",
    3016005: "Obvious Signs of Death",
    3016007: "Physically Unable to Perform",
    3016009: "Protocol/Policy Requirements Completed",
    3016011: "Return of Spontaneous Circulation (pulse or BP noted)",

    // eArrest.18 - End of EMS Cardiac Arrest Event
    3018001: "Expired in ED",
    3018003: "Expired in the Field",
    3018005: "Ongoing Resuscitation in ED",
    3018007: "ROSC in the Field",
    3018009: "ROSC in the ED",
    3018011: "Ongoing Resuscitation by Other EMS",

    // eArrest.20 - Who First Initiated CPR
    3020001: "Bystander",
    3020003: "Family Member",
    3020005: "Healthcare Provider (non-911 Responder)",
    3020007: "First Responder (EMS)",
    3020009: "First Responder (Law Enforcement)",
    3020011: "First Responder (non-EMS Fire)",
    3020013: "EMS Responder (transport EMS)",

    // eArrest.21 - Who First Applied the AED
    3021001: "Bystander",
    3021003: "Family Member",
    3021005: "Healthcare Provider (non-911 Responder)",
    3021007: "First Responder (EMS)",
    3021009: "First Responder (Law Enforcement)",
    3021011: "First Responder (non-EMS Fire)",
    3021013: "EMS Responder (transport EMS)",

    // eArrest.22 - Who First Defibrillated the Patient
    3022001: "Bystander",
    3022003: "Family Member",
    3022005: "Healthcare Provider (non-911 Responder)",
    3022007: "First Responder (EMS)",
    3022009: "First Responder (Law Enforcement)",
    3022011: "First Responder (non-EMS Fire)",
    3022013: "EMS Responder (transport EMS)",
}

const NEMSIS_eHistory_CODES = {
    // eHistory.01 - Barriers to Patient Care
    3101001: "Cultural, Custom, Religious",
    3101003: "Developmentally Impaired",
    3101005: "Hearing Impaired",
    3101007: "Language",
    3101009: "None Noted",
    3101011: "Obesity",
    3101013: "Physical Barrier (Unable to Access Patient)",
    3101015: "Physically Impaired",
    3101017: "Physically Restrained",
    3101019: "Psychologically Impaired",
    3101021: "Sight Impaired",
    3101023: "Speech Impaired",
    3101025: "Unattended or Unsupervised (including minors)",
    3101027: "Unconscious",
    3101029: "Uncooperative",
    3101031: "State of Emotional Distress",
    3101033: "Alcohol Use, Suspected",
    3101035: "Drug Use, Suspected",

    // eHistory.05 - Advance Directives
    3105001: "Family/Guardian request DNR (but no documentation)",
    3105003: "Living Will",
    3105005: "None",
    3105007: "Other",
    3105009: "Other Healthcare Advanced Directive Form",
    3105011: "State EMS DNR or Medical Order Form",

    // eHistory.09 - Medical History Obtained From
    3109001: "Bystander/Other",
    3109003: "Family",
    3109005: "Health Care Personnel",
    3109007: "Patient",

    // eHistory.10 - The Patient's Type of Immunization
    9910001: "Anthrax",
    9910003: "Cholera",
    9910005: "DPT / TDaP (Diphtheria, Pertussis, Tetanus)",
    9910007: "Hemophilus Influenza B",
    9910009: "Hepatitis A",
    9910011: "Hepatitis B",
    9910013: "Human Papilloma Virus (HPV)",
    9910015: "Influenza-H1N1",
    9910017: "Influenza-Other",
    9910019: "Influenza-Seasonal (In past 12 months)",
    9910021: "Lyme Disease",
    9910023: "Meningococcus",
    9910025: "MMR (Measles, Mumps, Rubella)",
    9910027: "Other-Not Listed",
    9910029: "Plague",
    9910031: "Pneumococcal (Pneumonia)",
    9910033: "Polio",
    9910035: "Rabies",
    9910037: "Rotavirus",
    9910039: "Shingles",
    9910041: "Small Pox",
    9910043: "Tetanus",
    9910045: "Tuberculosis",
    9910047: "Typhoid",
    9910049: "Varicella (Chickenpox)",
    9910051: "Yellow Fever",
    9910053: "None",

    // eHistory.14 - Current Medication Dosage Unit
    3114001: "Centimeters (cm)",
    3114003: "Grams (gms)",
    3114005: "Drops (gtts)",
    3114007: "Inches (in)",
    3114009: "International Units (IU)",
    3114011: "Keep Vein Open (kvo)",
    3114013: "Liters Per Minute (l/min [fluid])",
    3114015: "Liters (l)",
    3114017: "Liters Per Minute (LPM [gas])",
    3114019: "Micrograms (mcg)",
    3114021: "Micrograms per Kilogram per Minute (mcg/kg/min)",
    3114023: "Micrograms per Minute (mcg/min)",
    3114025: "Milliequivalents (mEq)",
    3114027: "Metered Dose (MDI)",
    3114029: "Milligrams (mg)",
    3114031: "Milligrams per Kilogram (mg/kg)",
    3114033: "Milligrams per Kilogram Per Minute (mg/kg/min)",
    3114035: "Milligrams per Minute (mg/min)",
    3114037: "Milliliters (ml)",
    3114039: "Milliliters per Hour (ml/hr)",
    3114041: "Other",
    3114043: "Puffs",
    3114045: "Units per Hour (units/hr)",
    3114047: "Micrograms per Kilogram (mcg/kg)",
    3114049: "Units",
    3114051: "Units per Kilogram per Hour (units/kg/hr)",
    3114053: "Units per Kilogram (units/kg)",

    // eHistory.15 - Current Medication Administration Route
    9927001: "Blow-By",
    9927003: "Buccal",
    9927005: "Endotracheal Tube (ET)",
    9927007: "Gastrostomy Tube",
    9927009: "Inhalation",
    9927011: "Intraarterial",
    9927013: "Intradermal",
    9927015: "Intramuscular (IM)",
    9927017: "Intranasal",
    9927019: "Intraocular",
    9927021: "Intraosseous (IO)",
    9927023: "Intravenous (IV)",
    9927025: "Nasal Cannula",
    9927027: "Nasogastric",
    9927029: "Nasotracheal Tube",
    9927031: "Non-Rebreather Mask",
    9927033: "Ophthalmic",
    9927035: "Oral",
    9927037: "Other/miscellaneous",
    9927039: "Otic",
    9927041: "Re-breather mask",
    9927043: "Rectal",
    9927045: "Subcutaneous",
    9927047: "Sublingual",
    9927049: "Topical",
    9927051: "Tracheostomy",
    9927053: "Transdermal",
    9927055: "Urethral",
    9927057: "Ventimask",
    9927059: "Wound",
    9927061: "Portacath",
    9927063: "Auto Injector",
    9927065: "BVM",
    9927067: "CPAP",
    9927069: "IV Pump",
    9927071: "Nebulizer",
    9927073: "Umbilical Artery Catheter",
    9927075: "Umbilical Venous Catheter",

    // eHistory.17 - Alcohol/Drug Use Indicators
    3117001: "Alcohol Containers/Paraphernalia at Scene",
    3117003: "Drug Paraphernalia at Scene",
    3117005: "Patient Admits to Alcohol Use",
    3117007: "Patient Admits to Drug Use",
    3117009: "Positive Level known from Law Enforcement or Hospital Record",
    3117013: "Physical Exam Indicates Suspected Alcohol or Drug Use",

    // eHistory.18 - Pregnancy
    3118001: "No",
    3118003: "Possible, Unconfirmed",
    3118005: "Yes, Confirmed 12 to 20 Weeks",
    3118007: "Yes, Confirmed Greater Than 20 Weeks",
    3118009: "Yes, Confirmed Less Than 12 Weeks",
    3118011: "Yes, Weeks Unknown",

    // eHistory.20 - Current Medication Frequency
    3120001: "qi (every hour)",
    3120003: "q2h (every 2 hours)",
    3120005: "q3h (every 3 hours)",
    3120007: "q4h (every 4 hours)",
    3120009: "qid (four times a day)",
    3120011: "tad (three times a day)",
    3120013: "ac (before meals)",
    3120015: "pc (after meals)",
    3120017: "bid (twice a day)",
    3120019: "qd (every day)",
    3120021: "qd-am (every day in the morning)",
    3120023: "qd-pm (every day in the evening)",
    3120025: "has (at bedtime)",
    3120027: "sod (every other day)",
    3120029: "prn (as needed)",
}

const NEMSIS_eNarrative_CODES = {
    // no codes
}

const NEMSIS_eVitals_CODES = {
    // eVitals.02
    9923001: "No",
    9923003: "Yes",

    // eVitals.03
    9901001: "Agonal/Idioventricular",
    9901003: "Asystole", 
    9901005: "Artifact",
    9901007: "Atrial Fibrillation",
    9901009: "Atrial Flutter",
    9901011: "AV Block-1st Degree",
    9901013: "AV Block-2nd Degree-Type 1",
    9901015: "AV Block-2nd Degree-Type 2", 
    9901017: "AV Block-3rd Degree",
    9901019: "Junctional",
    9901021: "Left Bundle Branch Block",
    9901023: "Non-STEMI Anterior Ischemia",
    9901025: "Non-STEMI Inferior Ischemia",
    9901027: "Non-STEMI Lateral Ischemia",
    9901029: "Non-STEMI Posterior Ischemia",
    9901030: "Non-STEMI Septal Ischemia",
    9901031: "Other",
    9901033: "Paced Rhythm",
    9901035: "PEA",
    9901037: "Premature Atrial Contractions",
    9901039: "Premature Ventricular Contractions",
    9901041: "Right Bundle Branch Block",
    9901043: "Sinus Arrhythmia",
    9901045: "Sinus Bradycardia",
    9901047: "Sinus Rhythm",
    9901049: "Sinus Tachycardia",
    9901051: "STEMI Anterior Ischemia",
    9901053: "STEMI Inferior Ischemia",
    9901055: "STEMI Lateral Ischemia",
    9901057: "STEMI Posterior Ischemia",
    9901058: "STEMI Septal Ischemia",
    9901059: "Supraventricular Tachycardia",
    9901061: "Torsades De Points",
    9901063: "Unknown AED Non-Shockable Rhythm",
    9901065: "Unknown AED Shockable Rhythm",
    9901067: "Ventricular Fibrillation",
    9901069: "Ventricular Tachycardia (With Pulse)",
    9901071: "Ventricular Tachycardia (Pulseless)",

    // eVitals.04
    3304000: "2 Lead ECG (pads or paddles)",
    3304001: "3 Lead",
    3304003: "4 Lead",
    3304005: "5 Lead",
    3304007: "12 Lead-Left Sided (Normal)",
    3304009: "12 Lead-Right Sided",
    3304011: "15 Lead",
    3304013: "18 Lead",
    3304015: "Other",

    // eVitals.05
    3305001: "Computer Interpretation",
    3305003: "Manual Interpretation",
    3305005: "Transmission with No Interpretation",
    3305007: "Transmission with Remote Interpretation",

    // eVitals.19 - Glasgow Coma Score-Eye
    1: "No eye movement when assessed (All Age Groups)",
    2: "Opens Eyes to painful stimulation (All Age Groups)",
    3: "Opens Eyes to verbal stimulation (All Age Groups)",
    4: "Opens Eyes spontaneously (All Age Groups)",

    // eVitals.20 - Glasgow Coma Score-Verbal
    1: "No verbal/vocal response (All Age Groups)",
    2: "Incomprehensible sounds (>2 Years); Inconsolable, agitated",
    3: "Inappropriate words (>2 Years); Inconsistently consolable, moaning",
    4: "Confused (>2 Years); Cries but is consolable, inappropriate interactions",
    5: "Oriented (>2 Years); Smiles, oriented to sounds, follows objects, interacts",

    // eVitals.21 - Glasgow Coma Score-Motor
    1: "No Motor Response (All Age Groups)",
    2: "Extension to pain (All Age Groups)",
    3: "Flexion to pain (All Age Groups)",
    4: "Withdrawal from pain (All Age Groups)",
    5: "Localizing pain (All Age Groups)",
    6: "Obeys commands (>2Years); Appropriate response to stimulation",

    // eVitals.22 - Glasgow Coma Score-Qualifier
    3322001: "Eye Obstruction Prevents Eye Assessment",
    3322003: "Initial GCS has legitimate values without interventions such as intubation and sedation",
    3322005: "Patient Chemically Paralyzed",
    3322007: "Patient Chemically Sedated",
    3322009: "Patient Intubated",

    // eVitals.26 - Level of Responsiveness (AVPU)
    3326001: "Alert",
    3326003: "Verbal",
    3326005: "Painful",
    3326007: "Unresponsive",

    // eVitals.28 - Pain Scale Type
    3328001: "FLACC (Face, Legs, Activity, Cry, Consolability)",
    3328003: "Numeric (0-10)",
    3328005: "Other",
    3328007: "Wong-Baker (FACES)",

    // eVitals.29 - Stroke Scale Score
    3329001: "Negative",
    3329003: "Non-Conclusive",
    3329005: "Positive",

    // eVitals.30 - Stroke Scale Type
    3330001: "Cincinnati Prehospital Stroke Scale (CPSS)",
    3330004: "Los Angeles Prehospital Stroke Screen (LAPSS)",
    3330005: "Massachusetts Stroke Scale (MSS)",
    3330007: "Miami Emergency Neurologic Deficit Exam (MEND)",
    3330009: "NIH Stroke Scale (NIHSS)",
    3330011: "Other Stroke Scale Type",
    3330013: "FAST-ED",
    3330015: "Boston Stroke Scale (BOSS)",
    3330017: "Ontario Prehospital Stroke Scale (OPSS)",
    3330019: "Melbourne Ambulance Stroke Screen (MASS)",
    3330021: "Rapid Arterial oCclusion Evaluation (RACE)",
    3330023: "Los Angeles Motor Score (LAMS)",

    // eVitals.31 - Reperfusion Checklist
    3331001: "Definite Contraindications to Thrombolytic Use",
    3331003: "No Contraindications to Thrombolytic Use",
    3331005: "Possible Contraindications to Thrombolytic Use"
}

const NEMSIS_eLabs_CODES = {
    // eLabs.02 - Study/Result Prior to this Unit's EMS Care
    9923001: "No",
    9923003: "Yes",

    // eLabs.03 - Laboratory Result Type
    3403001: "Alanine Transaminase",
    3403003: "Alcohol-Blood",
    3403005: "Alcohol-Breath", 
    3403007: "Alkaline Phosphatase",
    3403009: "Amylase",
    3403013: "Aspartate Transaminase",
    3403015: "B-Type Natriuretic Peptide (BNP)",
    3403017: "Base Excess",
    3403019: "Bilirubin-Direct",
    3403021: "Bilirubin-Total",
    3403023: "BUN",
    3403025: "Calcium-Ionized",
    3403027: "Calcium-Serum",
    3403029: "Carbon Dioxide-Partial Pressure",
    3403033: "Chloride",
    3403035: "Creatine Kinase",
    3403037: "Creatine Kinase-MB",
    3403039: "Creatinine",
    3403041: "Gamma Glutamyl Transpeptidase",
    3403043: "Glucose",
    3403045: "Hematocrit",
    3403047: "Hemoglobin",
    3403049: "Human Chorionic Gonadotropin-Serum",
    3403051: "Human Chorionic Gonadotropin-Urine",
    3403053: "International Normalized Ratio (INR)",
    3403055: "Lactate Dehydrogenase",
    3403057: "Lactate-Arterial",
    3403059: "Lactate-Venous",
    3403061: "Lipase",
    3403063: "Magnesium",
    3403065: "Oxygen-Partial Pressure",
    3403067: "Partial Thromboplastin Time",
    3403071: "pH-ABG",
    3403073: "pH-Venous",
    3403075: "Platelets",
    3403077: "Potassium",
    3403079: "Prothrombin Time",
    3403081: "Red Blood Cells",
    3403083: "Sodium",
    3403085: "Troponin",
    3403087: "White Blood Cells",
    3403089: "Ammonia",
    3403091: "Bicarbonate (HCO3)",
    3403093: "Carboxyhemoglobin",
    3403095: "CO2, Total (Bicarbonate)",
    3403097: "CRP (C-Reactive Protein)",
    3403099: "Dilantin",
    3403101: "Leukocytes",
    3403103: "Nitrates",
    3403105: "Phenobarbital",
    3403107: "Protein",
    3403109: "Salicylate",
    3403111: "Specific Gravity",
    3403113: "Tylenol",
    3403115: "ACT Celite (ACTc)",
    3403117: "ACT Kaolin (ACTk)",
    3403119: "Bands",
    3403121: "Basophils",
    3403123: "Creatine Phosphokinase (CK)",
    3403125: "Eosinophils",
    3403127: "Erythrocyte Count",
    3403129: "Erythrocyte Morphology",
    3403131: "Erythrocyte Sedimentation Rate",
    3403133: "High-Sensitivity C-reactive Protein (hs-CRP)",
    3403135: "Lymphocytes",
    3403137: "Mean Corpuscular Hemoglobin (MCH)",
    3403139: "Mean Corpuscular Hemoglobin Concentration (MCHC)",
    3403141: "Mean Corpuscular Volume (MCV)",
    3403143: "Monocytes",
    3403145: "Myoglobin",
    3403147: "Neutrophils",
    3403149: "Oxygen Saturation (SaO2)",
    3403151: "Oxygen Volume/Content (SVO2)",
    3403153: "Phosphorus (PO4)",
    3403155: "Prothromblin test time (PT/INR)",
    3403157: "Red Cell Distribution Width (RDW)",
    3403159: "Reticulocyte Count",
    3403161: "Troporin T (cTnT)",

    // eLabs.05 - Imaging Study Type
    3405001: "CAT Scan",
    3405003: "Magnetic Resonance Imaging (MRI)",
    3405005: "Other",
    3405007: "PET Scan", 
    3405009: "Ultrasound",
    3405011: "X-ray",
    3405013: "Angiography"
}

const NEMSIS_eExam_CODES = {
    // eExam
    3502001: "Blue",
    3502003: "Green",
    3502005: "Grey", 
    3502007: "Orange",
    3502009: "Pink",
    3502011: "Purple",
    3502013: "Red",
    3502015: "White",
    3502017: "Yellow",

    3504001: "Clammy",
    3504003: "Cold",
    3504005: "Cyanotic",
    3504007: "Diaphoretic", 
    3504009: "Dry",
    3504011: "Flushed",
    3504013: "Hot",
    3504015: "Jaundiced",
    3504017: "Lividity",
    3504019: "Mottled",
    3504021: "Normal",
    3504023: "Not Done",
    3504025: "Pale",
    3504027: "Poor Turgor",
    3504029: "Red (Erythematous)",
    3504031: "Tenting",
    3504033: "Warm",
    3504035: "Capillary Nail Bed Refill less than 2 seconds",
    3504037: "Capillary Nail Bed Refill 2-4 seconds",
    3504039: "Capillary Nail Bed Refill more than 4 seconds",

    3505001: "Abrasion",
    3505003: "Avulsion",
    3505005: "Bleeding Controlled",
    3505007: "Bleeding Uncontrolled",
    3505009: "Burn-Blistering",
    3505011: "Burn-Charring",
    3505013: "Burn-Redness",
    3505015: "Burn-White/Waxy",
    3505017: "Decapitation",
    3505019: "Deformity",
    3505021: "Drainage",
    3505023: "Foreign Body",
    3505029: "Laceration",
    3505031: "Mass/Lesion",
    3505033: "Normal",
    3505035: "Not Done",
    3505037: "Pain",
    3505039: "Puncture/Stab Wound",
    3505045: "Gunshot Wound",
    3505047: "Crush Injury",
    3505049: "Swelling",
    3505051: "Contusion",
    3505053: "Tenderness",

    3506001: "Abrasion",
    3506003: "Asymmetric Smile or Droop",
    3506005: "Avulsion",
    3506007: "Bleeding Controlled",
    3506009: "Bleeding Uncontrolled",
    3506011: "Burn-Blistering",
    3506013: "Burn-Charring",
    3506015: "Burn-Redness",
    3506017: "Burn-White/Waxy",
    3506021: "Deformity",
    3506023: "Drainage",
    3506025: "Foreign Body",
    3506031: "Laceration",
    3506033: "Mass/Lesion",
    3506035: "Normal",
    3506037: "Not Done",
    3506039: "Pain",
    3506041: "Puncture/Stab Wound",
    3506047: "Gunshot Wound",
    3506049: "Crush Injury",
    3506051: "Tenderness",
    3506053: "Swelling",
    3506055: "Contusion",

    3507001: "Abrasion",
    3507003: "Avulsion",
    3507005: "Bleeding Controlled",
    3507007: "Bleeding Uncontrolled",
    3507009: "Burn-Blistering",
    3507011: "Burn-Charring",
    3507013: "Burn-Redness",
    3507015: "Burn-White/Waxy",
    3507017: "Decapitation",
    3507019: "Foreign Body",
    3507025: "JVD",
    3507027: "Laceration",
    3507029: "Normal",
    3507031: "Not Done",
    3507033: "Pain",
    3507035: "Puncture/Stab Wound",
    3507037: "Stridor",
    3507039: "Subcutaneous Air",
    3507045: "Tracheal Deviation-Left",
    3507047: "Tracheal Deviation-Right",
    3507049: "Gunshot Wound",
    3507051: "Crush Injury",
    3507053: "Swelling",
    3507055: "Contusion",
    3507057: "Deformity",
    3507059: "Tenderness",

    3509001: "Clicks",
    3509003: "Heart Sounds Decreased",
    3509005: "Murmur-Diastolic",
    3509007: "Murmur-Systolic",
    3509009: "Normal",
    3509011: "Not Done",
    3509013: "Rubs",
    3509015: "S1",
    3509017: "S2",
    3509019: "S3",
    3509021: "S4",

    3510001: "Generalized",
    3510003: "Left Lower Quadrant",
    3510005: "Left Upper Quadrant",
    3510007: "Periumbilical",
    3510009: "Right Lower Quadrant",
    3510011: "Right Upper Quadrant",
    3510013: "Epigastric",

    3511001: "Abrasion",
    3511003: "Avulsion",
    3511005: "Bleeding Controlled",
    3511007: "Bleeding Uncontrolled",
    3511009: "Bowel Sounds-Absent",
    3511011: "Bowel Sounds-Present",
    3511013: "Burn-Blistering",
    3511015: "Burn-Charring",
    3511017: "Burn-Redness",
    3511019: "Burn-White/Waxy",
    3511021: "Distention",
    3511023: "Foreign Body",
    3511025: "Guarding",
    3511031: "Laceration",
    3511033: "Mass/Lesion",
    3511035: "Mass-Pulsating",
    3511037: "Normal",
    3511039: "Not Done",
    3511041: "Pain",
    3511043: "Pregnant-Palpable Uterus",
    3511045: "Puncture/Stab Wound",
    3511051: "Tenderness",
    3511053: "Gunshot Wound",
    3511055: "Crush Injury",
    3511057: "Swelling",
    3511059: "Contusion",
    3511061: "Deformity",
    3511063: "Rebound Tenderness",
    3511065: "Rigidity",

    3512001: "Abrasion",
    3512003: "Avulsion",
    3512005: "Bleeding Controlled",
    3512007: "Bleeding Uncontrolled",
    3512009: "Bleeding-Rectal",
    3512011: "Bleeding-Urethral",
    3512013: "Bleeding-Vaginal",
    3512015: "Burn-Blistering",
    3512017: "Burn-Charring",
    3512019: "Burn-Redness",
    3512021: "Burn-White/Waxy",
    3512023: "Deformity",
    3512025: "Foreign Body",
    3512027: "Genital Injury",
    3512033: "Laceration",
    3512035: "Mass/Lesion",
    3512037: "Normal",
    3512039: "Not Done",
    3512041: "Pain",
    3512043: "Pelvic Fracture",
    3512045: "Pelvic Instability",
    3512047: "Penile Priapism/Erection",
    3512049: "Pregnant-Crowning",
    3512051: "Puncture/Stab Wound",
    3512057: "Tenderness",
    3512059: "Gunshot Wound",
    3512061: "Crush Injury",
    3512063: "Swelling",
    3512065: "Contusion",

    3513001: "Back-General",
    3513003: "Cervical-Left",
    3513005: "Cervical-Midline",
    3513007: "Cervical-Right",
    3513009: "Lumbar-Left",
    3513011: "Lumbar-Midline",
    3513013: "Lumbar-Right",
    3513015: "Thoracic-Left",
    3513017: "Thoracic-Midline",
    3513019: "Thoracic-Right",
    3513021: "Sacral-Left",
    3513023: "Sacral-Midline",
    3513025: "Sacral-Right",

    3514001: "Abrasion",
    3514003: "Avulsion",
    3514005: "Bleeding Controlled",
    3514007: "Bleeding Uncontrolled",
    3514009: "Burn-Blistering",
    3514011: "Burn-Charring",
    3514013: "Burn-Redness",
    3514015: "Burn-White/Waxy",
    3514017: "Deformity",
    3514019: "Foreign Body",
    3514025: "Laceration",
    3514027: "Normal",
    3514029: "Not Done",
    3514031: "Pain",
    3514033: "Pain with Range of Motion",
    3514035: "Puncture/Stab Wound",
    3514041: "Tenderness Costovertebral Angle",
    3514043: "Tenderness Midline Spinous Process",
    3514045: "Tenderness Paraspinous",
    3514047: "Gunshot Wound",
    3514049: "Crush Injury",
    3514051: "Swelling",
    3514053: "Contusion",
    3514055: "Tenderness",

    3515001: "Ankle-Left",
    3515003: "Ankle-Right",
    3515005: "Arm-Upper-Left",
    3515007: "Arm-Upper-Right",
    3515009: "Elbow-Left",
    3515011: "Elbow-Right",
    3515013: "Finger-2nd (Index)-Left",
    3515015: "Finger-2nd (Index)-Right",
    3515017: "Finger-3rd (Middle)-Left",
    3515019: "Finger-3rd (Middle)-Right",
    3515021: "Finger-4th (Ring)-Left",
    3515023: "Finger-4th (Ring)-Right",
    3515025: "Finger-5th (Smallest)-Left",
    3515027: "Finger-5th (Smallest)-Right",
    3515029: "Foot-Dorsal-Left",
    3515031: "Foot-Dorsal-Right",
    3515033: "Foot-Plantar-Left",
    3515035: "Foot-Plantar-Right",
    3515037: "Arm-Lower-Left",
    3515039: "Arm-Lower-Right",
    3515041: "Hand-Dorsal-Left",
    3515043: "Hand-Dorsal-Right",
    3515045: "Hand-Palm-Left",
    3515047: "Hand-Palm-Right",
    3515049: "Hip-Left",
    3515051: "Hip-Right",
    3515053: "Knee-Left",
    3515055: "Knee-Right",
    3515057: "Leg-Lower-Left",
    3515059: "Leg-Lower-Right",
    3515061: "Leg-Upper-Left",
    3515063: "Leg-Upper-Right",
    3515065: "Shoulder-Left",
    3515067: "Shoulder-Right",
    3515069: "Thumb-Left",
    3515071: "Thumb-Right",
    3515073: "Toe-1st (Big)-Left",
    3515075: "Toe-1st (Big)-Right",
    3515077: "Toe-2nd-Left",
    3515079: "Toe-2nd-Right",
    3515081: "Toe-3rd-Left",
    3515083: "Toe-3rd-Right",
    3515085: "Toe-4th-Left",
    3515087: "Toe-4th-Right",
    3515089: "Toe-5th (Smallest)-Left",
    3515091: "Toe-5th (Smallest)-Right",
    3515093: "Wrist-Left",
    3515095: "Wrist-Right",
    3515097: "Arm-Whole Arm and Hand-Left",
    3515099: "Arm-Whole Arm and Hand-Right",
    3515101: "Hand-Whole Hand-Left",
    3515103: "Hand-Whole Hand-Right",
    3515105: "Leg-Whole Leg-Left",
    3515107: "Leg-Whole Leg-Right",
    3515109: "Foot-Whole Foot-Left",
    3515111: "Foot-Whole Foot-Right",

    3516001: "Abrasion",
    3516003: "Amputation-Acute",
    3516005: "Amputation-Previous",
    3516007: "Avulsion",
    3516009: "Bleeding Controlled",
    3516011: "Bleeding Uncontrolled",
    3516013: "Burn-Blistering",
    3516015: "Burn-Charring",
    3516017: "Burn-Redness",
    3516019: "Burn-White/Waxy",
    3516021: "Clubbing (of fingers)",
    3516023: "Crush Injury",
    3516025: "Deformity",
    3516027: "Dislocation",
    3516029: "Edema",
    3516031: "Foreign Body",
    3516033: "Fracture-Closed",
    3516035: "Fracture-Open",
    3516041: "Laceration",
    3516043: "Motor Function-Abnormal/Weakness",
    3516045: "Motor Function-Absent",
    3516047: "Motor Function-Normal",
    3516049: "Normal",
    3516051: "Not Done",
    3516053: "Pain",
    3516055: "Paralysis",
    3516057: "Pulse-Abnormal",
    3516059: "Pulse-Absent",
    3516061: "Pulse-Normal",
    3516063: "Puncture/Stab Wound",
    3516065: "Sensation-Abnormal",
    3516067: "Sensation-Absent",
    3516069: "Sensation-Normal",
    3516075: "Tenderness",
    3516077: "Gunshot Wound",
    3516079: "Swelling",
    3516081: "Contusion",
    3516083: "Arm Drift",

    3517001: "Bilateral",
    3517003: "Left",
    3517005: "Right",

    3518001: "1-mm",
    3518003: "2-mm",
    3518005: "3-mm",
    3518007: "4-mm",
    3518009: "5-mm",
    3518011: "6-mm",
    3518013: "7-mm",
    3518015: "8-mm or >",
    3518017: "Blind",
    3518019: "Cataract Present",
    3518021: "Clouded",
    3518023: "Deformity",
    3518025: "Dysconjugate Gaze",
    3518027: "Foreign Body",
    3518029: "Glaucoma Present",
    3518031: "Hyphema",
    3518033: "Jaundiced Sclera",
    3518035: "Missing",
    3518037: "Non-Reactive",
    3518039: "Not Done",
    3518041: "Non-Reactive Prosthetic",
    3518043: "Nystagmus Noted",
    3518045: "Open Globe",
    3518047: "PERRL",
    3518049: "Pupil-Irregular/Teardrop",
    3518051: "Reactive",
    3518053: "Sluggish",
    3518055: "Swelling",
    3518057: "Contusion",
    3518059: "Puncture/Stab Wound",
    3518061: "Dilated",
    3518063: "Pin Point",

    3519001: "Combative",
    3519003: "Confused",
    3519005: "Hallucinations",
    3519007: "Normal Baseline for Patient",
    3519009: "Not Done",
    3519011: "Oriented-Person",
    3519013: "Oriented-Place",
    3519015: "Oriented-Event",
    3519017: "Oriented-Time",
    3519021: "Unresponsive",
    3519023: "Agitation",
    3519025: "Somnolent",
    3519027: "Stupor",
    3519029: "Altered mental status, unspecified",
    3519031: "Developmentally Impaired",
    3519033: "Disorientation, unspecified",
    3519035: "Pharmacologically Paralyzed",
    3519037: "Pharmacologically Sedated",
    3519039: "Psychologically Impaired",
    3519041: "Slowness and poor responsiveness",
    3519043: "State of emotional shock and stress, unspecified",
    3519045: "Strange and inexplicable behavior",
    3519047: "Uncooperative",
    3519049: "Unspecified coma",

    3520001: "Aphagia",
    3520003: "Aphasia",
    3520005: "Cerebellar Function-Abnormal",
    3520007: "Cerebellar Function-Normal",
    3520009: "Decerebrate Posturing",
    3520011: "Decorticate Posturing",
    3520013: "Gait-Abnormal",
    3520015: "Gait-Normal",
    3520017: "Hemiplegia-Left",
    3520019: "Hemiplegia-Right",
    3520021: "Normal Baseline for Patient",
    3520023: "Not Done",
    3520026: "Status Seizure",
    3520055: "Other Seizures",
    3520027: "Speech Normal",
    3520029: "Speech Slurring",
    3520031: "Strength-Asymmetric",
    3520033: "Strength-Normal",
    3520035: "Strength-Symmetric",
    3520037: "Tremors",
    3520039: "Weakness-Facial Droop-Left",
    3520041: "Weakness-Facial Droop-Right",
    3520043: "Weakness-Left Sided",
    3520045: "Weakness-Right Sided",
    3520051: "Arm Drift-Left",
    3520053: "Arm Drift-Right",

    3521001: "No",
    3521003: "Yes-Resolved Prior to EMS Arrival",
    3521005: "Yes-Resolved in EMS Presence",

    3522001: "Left",
    3522003: "Right",
    3522005: "Bilateral",

    3523001: "Breath Sounds-Absent",
    3523003: "Breath Sounds-Decreased",
    3523005: "Breath Sounds-Equal",
    3523007: "Breath Sounds-Normal",
    3523009: "Foreign Body",
    3523011: "Increased Respiratory Effort",
    3523013: "Normal",
    3523015: "Not Done",
    3523017: "Pain",
    3523019: "Pain with Inspiraton/Expiration",
    3523021: "Rales",
    3523023: "Rhonchi",
    3523025: "Rhonchi/Wheezing",
    3523027: "Stridor",
    3523029: "Wheezing-Expiratory",
    3523031: "Wheezing-Inspiratory",

    3524001: "Left - Anterior",
    3524003: "Left - Posterior",
    3524005: "Right - Anterior",
    3524007: "Right - Posterior",
    3524009: "General - Anterior",
    3524011: "General - Posterior",
    3524013: "Left - Side",
    3524015: "Right - Side",

    3525001: "Abrasion",
    3525003: "Avulsion",
    3525005: "Accessory Muscles Used with Breathing",
    3525007: "Bleeding Controlled",
    3525009: "Bleeding Uncontrolled",
    3525011: "Burn-Blistering",
    3525013: "Burn-Charing",
    3525015: "Burn-Redness",
    3525017: "Burn-White/Waxy",
    3525019: "Crush Injury",
    3525021: "Deformity",
    3525023: "Flail Segment",
    3525025: "Implanted Device",
    3525027: "Laceration",
    3525029: "Normal",
    3525031: "Not Done",
    3525033: "Pain",
    3525035: "Pain with Inspiration/Expiration",
    3525037: "Puncture/Stab Wound",
    3525039: "Retraction",
    3525041: "Tenderness",
    3525043: "Gunshot Wound",
    3525045: "Swelling",
    3525047: "Contusion",
    3525049: "Tenderness-General",
}

const NEMSIS_eProtocols_CODES = {
    9914001: "Airway",
    9914003: "Airway-Failed",
    9914005: "Airway-Obstruction/Foreign Body",
    9914007: "Airway-Rapid Sequence Induction (RSI-Paralytic)",
    9914009: "Airway-Sedation Assisted (Non-Paralytic)",
    9914011: "Cardiac Arrest-Asystole",
    9914013: "Cardiac Arrest-Hypothermia-Therapeutic",
    9914015: "Cardiac Arrest-Pulseless Electrical Activity",
    9914017: "Cardiac Arrest-Ventricular Fibrillation/ Pulseless Ventricular Tachycardia",
    9914019: "Cardiac Arrest-Post Resuscitation Care",
    9914021: "Environmental-Altitude Sickness",
    9914023: "Environmental-Cold Exposure",
    9914025: "Environmental-Frostbite/Cold Injury",
    9914027: "Environmental-Heat Exposure/Exhaustion",
    9914029: "Environmental-Heat Stroke/Hyperthermia",
    9914031: "Environmental-Hypothermia",
    9914033: "Exposure-Airway/Inhalation Irritants",
    9914035: "Exposure-Biological/Infectious",
    9914037: "Exposure-Blistering Agents",
    9914041: "Exposure-Chemicals to Eye",
    9914043: "Exposure-Cyanide",
    9914045: "Exposure-Explosive/ Blast Injury",
    9914047: "Exposure-Nerve Agents",
    9914049: "Exposure-Radiologic Agents",
    9914051: "General-Back Pain",
    9914053: "General-Behavioral/Patient Restraint",
    9914055: "General-Cardiac Arrest",
    9914057: "General-Dental Problems",
    9914059: "General-Epistaxis",
    9914061: "General-Fever",
    9914063: "General-Individualized Patient Protocol",
    9914065: "General-Indwelling Medical Devices/Equipment",
    9914067: "General-IV Access",
    9914069: "General-Medical Device Malfunction",
    9914071: "General-Pain Control",
    9914073: "General-Spinal Immobilization/Clearance",
    9914075: "General-Universal Patient Care/ Initial Patient Contact",
    9914077: "Injury-Amputation",
    9914079: "Injury-Bites and Envenomations-Land",
    9914081: "Injury-Bites and Envenomations-Marine",
    9914083: "Injury-Bleeding/ Hemorrhage Control",
    9914085: "Injury-Burns-Thermal",
    9914087: "Injury-Cardiac Arrest",
    9914089: "Injury-Crush Syndrome",
    9914091: "Injury-Diving Emergencies",
    9914093: "Injury-Drowning/Near Drowning",
    9914095: "Injury-Electrical Injuries",
    9914097: "Injury-Extremity",
    9914099: "Injury-Eye",
    9914101: "Injury-Head",
    9914103: "Injury-Impaled Object",
    9914105: "Injury-Multisystem",
    9914107: "Injury-Spinal Cord",
    9914109: "Medical-Abdominal Pain",
    9914111: "Medical-Allergic Reaction/Anaphylaxis",
    9914113: "Medical-Altered Mental Status",
    9914115: "Medical-Bradycardia",
    9914117: "Medical-Cardiac Chest Pain",
    9914119: "Medical-Diarrhea",
    9914121: "Medical-Hyperglycemia",
    9914123: "Medical-Hypertension",
    9914125: "Medical-Hypoglycemia/Diabetic Emergency",
    9914127: "Medical-Hypotension/Shock (Non-Trauma)",
    9914129: "Medical-Influenza-Like Illness/ Upper Respiratory Infection",
    9914131: "Medical-Nausea/Vomiting",
    9914133: "Medical-Newborn/ Neonatal Resuscitation",
    9914135: "General-Overdose/Poisoning/Toxic Ingestion",
    9914137: "Medical-Pulmonary Edema/CHF",
    9914139: "Medical-Respiratory Distress/Asthma/COPD/Reactive Airway",
    9914141: "Medical-Seizure",
    9914143: "Medical-ST-Elevation Myocardial Infarction (STEMI)",
    9914145: "Medical-Stroke/TIA",
    9914147: "Medical-Supraventricular Tachycardia (Including Atrial Fibrillation)",
    9914149: "Medical-Syncope",
    9914151: "Medical-Ventricular Tachycardia (With Pulse)",
    9914153: "Not Done",
    9914155: "OB/GYN-Childbirth/Labor/Delivery",
    9914157: "OB/GYN-Eclampsia",
    9914159: "OB/GYN-Gynecologic Emergencies",
    9914161: "OB/GYN-Pregnancy Related Emergencies",
    9914163: "OB/GYN-Post-partum Hemorrhage",
    9914165: "Other",
    9914167: "Exposure-Carbon Monoxide",
    9914169: "Cardiac Arrest-Do Not Resuscitate",
    9914171: "Cardiac Arrest-Special Resuscitation Orders",
    9914173: "Exposure-Smoke Inhalation",
    9914175: "General-Community Paramedicine / Mobile Integrated Healthcare",
    9914177: "General-Exception Protocol",
    9914179: "General-Extended Care Guidelines",
    9914181: "General-Interfacility Transfers",
    9914183: "General-Law Enforcement - Blood for Legal Purposes",
    9914185: "General-Law Enforcement - Assist with Law Enforcement Activity",
    9914187: "General-Neglect or Abuse Suspected",
    9914189: "General-Refusal of Care",
    9914191: "Injury-Mass/Multiple Casualties",
    9914193: "Injury-Thoracic",
    9914195: "Medical-Adrenal Insufficiency",
    9914197: "Medical-Apparent Life Threatening Event (ALTE)",
    9914199: "Medical-Tachycardia",
    9914201: "Cardiac Arrest-Determination of Death / Withholding Resuscitative Efforts",
    9914203: "Injury-Conducted Electrical Weapon (e.g., Taser)",
    9914205: "Injury-Facial Trauma",
    9914207: "Injury-General Trauma Management",
    9914209: "Injury-Lightning/Lightning Strike",
    9914211: "Injury-SCUBA Injury/Accidents",
    9914213: "Injury-Topical Chemical Burn",
    9914215: "Medical-Beta Blocker Poisoning/Overdose",
    9914217: "Medical-Calcium Channel Blocker Poisoning/Overdose",
    9914219: "Medical-Opioid Poisoning/Overdose",
    9914221: "Medical-Respiratory Distress-Bronchiolitis",
    9914223: "Medical-Respiratory Distress-Croup",
    9914225: "Medical-Stimulant Poisoning/Overdose",

    3602001: "Adult Only",
    3602003: "General",
    3602005: "Pediatric Only",
}

const NEMSIS_eMedications_CODES = {
    // eMedications
    9923001: "No",
    9923003: "Yes",

    9927001: "Blow-By",
    9927003: "Buccal",
    9927005: "Endotracheal Tube (ET)",
    9927007: "Gastrostomy Tube", 
    9927009: "Inhalation",
    9927011: "Intraarterial",
    9927013: "Intradermal",
    9927015: "Intramuscular (IM)",
    9927017: "Intranasal",
    9927019: "Intraocular",
    9927021: "Intraosseous (IO)",
    9927023: "Intravenous (IV)",
    9927025: "Nasal Cannula",
    9927027: "Nasogastric",
    9927029: "Nasotracheal Tube",
    9927031: "Non-Rebreather Mask",
    9927033: "Ophthalmic",
    9927035: "Oral",
    9927037: "Other/miscellaneous",
    9927039: "Otic",
    9927041: "Re-breather mask",
    9927043: "Rectal",
    9927045: "Subcutaneous",
    9927047: "Sublingual",
    9927049: "Topical",
    9927051: "Tracheostomy",
    9927053: "Transdermal",
    9927055: "Urethral",
    9927057: "Ventimask",
    9927059: "Wound",
    9927061: "Portacath",
    9927063: "Auto Injector",
    9927065: "BVM",
    9927067: "CPAP",
    9927069: "IV Pump",
    9927071: "Nebulizer",
    9927073: "Umbilical Artery Catheter",
    9927075: "Umbilical Venous Catheter",

    3706001: "Grams (gms)",
    3706003: "Inches (in)",
    3706005: "International Units (IU)",
    3706007: "Keep Vein Open (kvo)",
    3706009: "Liters (l)",
    3706013: "Metered Dose (MDI)",
    3706015: "Micrograms (mcg)",
    3706017: "Micrograms per Kilogram per Minute (mcg/kg/min)",
    3706019: "Milliequivalents (mEq)",
    3706021: "Milligrams (mg)",
    3706023: "Milligrams per Kilogram Per Minute (mg/kg/min)",
    3706025: "Milliliters (ml)",
    3706027: "Milliliters per Hour (ml/hr)",
    3706029: "Other",
    3706031: "Centimeters (cm)",
    3706033: "Drops (gtts)",
    3706035: "Liters Per Minute (LPM [gas])",
    3706037: "Micrograms per Minute (mcg/min)",
    3706039: "Milligrams per Kilogram (mg/kg)",
    3706041: "Milligrams per Minute (mg/min)",
    3706043: "Puffs",
    3706045: "Units per Hour (units/hr)",
    3706047: "Micrograms per Kilogram (mcg/kg)",
    3706049: "Units",
    3706051: "Units per Kilogram per Hour (units/kg/hr)",
    3706053: "Units per Kilogram (units/kg)",
    3706055: "Milligrams per Hour (mg/hr)",

    9916001: "Improved",
    9916003: "Unchanged",
    9916005: "Worse",

    3708001: "Altered Mental Status",
    3708003: "Apnea",
    3708005: "Bleeding",
    3708007: "Bradycardia",
    3708009: "Bradypnea",
    3708011: "Diarrhea",
    3708013: "Extravasation",
    3708015: "Hypertension",
    3708017: "Hyperthermia",
    3708019: "Hypotension",
    3708021: "Hypothermia",
    3708023: "Hypoxia",
    3708025: "Injury",
    3708029: "Nausea",
    3708031: "None",
    3708033: "Other",
    3708035: "Respiratory Distress",
    3708037: "Tachycardia",
    3708039: "Tachypnea",
    3708041: "Vomiting",
    3708043: "Itching",
    3708045: "Urticaria",

    9905001: "Advanced Emergency Medical Technician (AEMT)",
    9905002: "Emergency Medical Technician - Intermediate",
    9905003: "Emergency Medical Responder (EMR)",
    9905005: "Emergency Medical Technician (EMT)",
    9905007: "Paramedic",
    9905019: "Other Healthcare Professional",
    9905021: "Other Non-Healthcare Professional",
    9905025: "Physician",
    9905027: "Respiratory Therapist",
    9905029: "Student",
    9905031: "Critical Care Paramedic",
    9905033: "Community Paramedicine",
    9905035: "Nurse Practitioner",
    9905037: "Physician Assistant",
    9905039: "Licensed Practical Nurse (LPN)",
    9905041: "Registered Nurse",
    9905043: "Patient",
    9905045: "Lay Person",
    9905047: "Law Enforcement",
    9905049: "Family Member",
    9905051: "Fire Personnel (non EMS)",

    9918001: "On-Line (Remote Verbal Order)",
    9918003: "On-Scene",
    9918005: "Protocol (Standing Order)",
    9918007: "Written Orders (Patient Specific)",
}

const NEMSIS_eAirway_CODES = {
    // eAirway.01 - Indications for Invasive Airway
    4001001: "Adequate Airway Reflexes/Effort, Potential for Compromise",
    4001003: "Airway Reflex Compromised",
    4001005: "Apnea or Agonal Respirations", 
    4001007: "Illness Involving Airway",
    4001009: "Injury Involving Airway",
    4001011: "Other",
    4001013: "Ventilatory Effort Compromised",

    // eAirway.03 - Airway Device Being Confirmed
    4003001: "Cricothyrotomy Tube",
    4003003: "Endotracheal Tube",
    4003005: "Other-Invasive Airway",
    4003007: "SAD-Combitube",
    4003009: "SAD-King",
    4003011: "SAD-LMA",
    4003013: "SAD-Other",
    4003015: "Tracheostomy Tube",

    // eAirway.04 - Airway Device Placement Confirmed Method
    4004001: "Auscultation",
    4004003: "Bulb/Syringe Aspiration",
    4004005: "Colorimetric ETCO2",
    4004007: "Condensation in Tube",
    4004009: "Digital (Numeric) ETCO2",
    4004011: "Direct Re-Visualization of Tube in Place",
    4004013: "Endotracheal Tube Whistle (BAAM, etc.)",
    4004015: "Other",
    4004017: "Visualization of Vocal Cords",
    4004019: "Waveform ETCO2",
    4004021: "Chest Rise",

    // eAirway.06 - Type of Individual Confirming Airway Device Placement
    4006001: "Another Person on the Same Crew",
    4006003: "Other",
    4006005: "Person Performing Intubation",
    4006007: "Receiving Air Medical/EMS Crew",
    4006009: "Receiving Hospital Team",

    // eAirway.08 - Airway Complications Encountered
    4008001: "Adverse Event from Facilitating Drugs",
    4008003: "Bradycardia (<50)",
    4008005: "Cardiac Arrest",
    4008007: "Esophageal Intubation-Delayed Detection (After Tube Secured)",
    4008009: "Esophageal Intubation-Detected in Emergency Department",
    4008011: "Failed Intubation Effort",
    4008013: "Injury or Trauma to Patient from Airway Management Effort",
    4008015: "Other",
    4008017: "Oxygen Desaturation (<90%)",
    4008019: "Patient Vomiting/Aspiration",
    4008021: "Tube Dislodged During Transport/Patient Care",
    4008023: "Tube Was Not in Correct Position when EMS Crew/Team Assumed Care of the Patient",

    // eAirway.09 - Suspected Reasons for Failed Airway Management
    4009001: "Difficult Patient Airway Anatomy",
    4009003: "ETI Attempted, but Arrived At Destination Facility Before Accomplished",
    4009005: "Facial or Oral Trauma",
    4009007: "Inability to Expose Vocal Cords",
    4009009: "Inadequate Patient Relaxation/Presence of Protective Airway Reflexes",
    4009011: "Jaw Clenched (Trismus)",
    4009013: "Other",
    4009015: "Poor Patient Access",
    4009017: "Secretions/Blood/Vomit",
    4009019: "Unable to Position or Access Patient",
}

const NEMSIS_eDevice_CODES = {
    // eDevice.03 - Medical Device Event Type
    4103001: "12-Lead ECG",
    4103003: "Analysis (Button Pressed)", 
    4103005: "CO2",
    4103007: "Data Transmitted",
    4103009: "Defibrillation",
    4103011: "ECG-Monitor",
    4103013: "Heart Rate",
    4103015: "Invasive Pressure 1",
    4103017: "Invasive Pressure 2", 
    4103019: "No Shock Advised",
    4103021: "Non-Invasive BP",
    4103023: "Other",
    4103025: "Pacing Electrical Capture",
    4103027: "Pacing Started",
    4103029: "Pacing Stopped",
    4103031: "Patient Connected",
    4103033: "Power On",
    4103035: "Pulse Oximetry",
    4103037: "Pulse Rate",
    4103039: "Respiratory Rate",
    4103041: "Shock Advised",
    4103043: "Sync Off",
    4103045: "Sync On",
    4103047: "Temperature 1",
    4103049: "Temperature 2",

    // eDevice.06 - Medical Device Mode
    4106001: "Advisory",
    4106003: "Automated",
    4106005: "Demand",
    4106007: "Manual", 
    4106009: "Mid-Stream",
    4106011: "Sensing",
    4106013: "Side-Stream",

    // eDevice.07 - Medical Device ECG Lead
    4107001: "I",
    4107003: "II",
    4107005: "III",
    4107007: "AVR",
    4107009: "AVL",
    4107011: "AVF",
    4107013: "Paddle",
    4107015: "Pads",
    4107017: "V1",
    4107019: "V2",
    4107021: "V3",
    4107023: "V3r",
    4107025: "V4",
    4107027: "V4r",
    4107029: "V5",
    4107031: "V5r",
    4107033: "V6",
    4107035: "V6r",
    4107037: "V7",
    4107039: "V8",
    4107041: "V9",

    // eDevice.09 - Type of Shock
    4109001: "Biphasic",
    4109003: "Monophasic"
}

const NEMSIS_eDisposition_CODES = {
    // eDisposition.13 - How Patient Was Moved to Ambulance
    9909001: "Assisted/Walk",
    9909003: "Backboard",
    9909005: "Chair",
    9909007: "Carried",
    9909009: "Other",
    9909011: "Stairchair",
    9909013: "Stretcher",
    9909015: "Wheelchair",

    // eDisposition.14 - Position of Patient During Transport
    4214001: "Car Seat",
    4214003: "Fowlers (Semi-Upright Sitting)",
    4214005: "Lateral Left",
    4214007: "Lateral Right",
    4214009: "Other",
    4214011: "Prone",
    4214013: "Semi-Fowlers",
    4214015: "Sitting",
    4214017: "Supine",
    4214019: "Trendelenburg",

    // eDisposition.15 - How Patient Was Moved From Ambulance
    4215001: "Assisted/Walk",
    4215003: "Backboard",
    4215005: "Chair",
    4215007: "Carried",
    4215009: "Other",
    4215011: "Stairchair",
    4215013: "Stretcher",
    4215015: "Wheelchair",

    // eDisposition.16 - EMS Transport Method
    4216001: "Air Medical",
    4216003: "Ground",
    4216005: "Ground Ambulance",
    4216007: "Ground Non-Ambulance",
    4216009: "Other",
    4216011: "Watercraft",
    4216013: "Wheelchair Van",

    // eDisposition.17 - Transport Mode from Scene
    4217001: "Emergency (Lights and Sirens)",
    4217003: "Emergency (Lights Only)",
    4217005: "Emergency (Sirens Only)",
    4217007: "Non-Emergency",
    4217009: "Other",

    // eDisposition.18 - Additional Transport Mode Descriptors
    4218001: "Air Medical",
    4218003: "Ground Ambulance",
    4218005: "Ground Non-Ambulance",
    4218007: "Ground Wheelchair Van",
    4218009: "Other",
    4218011: "Watercraft",
    4218013: "Wheelchair Van",
    4218015: "Wheelchair Van Non-Ambulance",

    // eDisposition.19 - Final Patient Acuity
    4219001: "Critical",
    4219003: "Emergent",
    4219005: "Lower Acuity",
    4219007: "Non-Acute",

    // eDisposition.20 - Reason for Choosing Destination
    4220001: "Closest Facility",
    4220003: "Family/Patient Request",
    4220005: "Medical Control Order",
    4220007: "Most Appropriate Facility",
    4220009: "Other",
    4220011: "Protocol",
    4220013: "Specialty Care Required",
    4220015: "Trauma Center Required",
    4220017: "Transport Time",
    4220019: "Weather Conditions",
    4220021: "Workers Compensation",

    // eDisposition.21 - Type of Destination
    4221001: "Emergency Department",
    4221003: "Hospital",
    4221005: "Non-Hospital",
    4221007: "Other",
    4221009: "Specialty Care Center",

    // eDisposition.26 - Disposition Instructions Provided
    4226001: "No",
    4226003: "Not Applicable",
    4226005: "Other",
    4226007: "Yes",

    // eDisposition.27 - Unit Disposition
    4227001: "Patient Contact Made",
    4227003: "Cancelled on Scene",
    4227005: "Cancelled Prior to Arrival at Scene",
    4227007: "No Patient Contact",
    4227009: "No Patient Found",
    4227011: "Non-Patient Incident (Not Otherwise Listed)",

    // eDisposition.28 - Patient Evaluation/Care
    4228001: "Patient Care Provided",
    4228003: "Patient Care Refused",
    4228005: "Patient Care Not Required",
    4228007: "Patient Care Not Possible",

    // eDisposition.29 - Crew Disposition
    4229001: "Initiated and Continued Primary Care",
    4229003: "Initiated Primary Care and Transferred to Another EMS Crew",
    4229005: "Provided Care Supporting Primary EMS Crew",
    4229007: "Assumed Primary Care from Another EMS Crew",
    4229009: "Incident Support Services Provided (Including Standby)",
    4229011: "Back in Service, No Care/Support Services Required",
    4229013: "Back in Service, Care/Support Services Refused",

    // eDisposition.30 - Transport Disposition
    4230001: "Transport by This EMS Unit (This Crew Only)",
    4230003: "Transport by This EMS Unit, with a Member of Another Crew",
    4230005: "Transport by Another EMS Unit",
    4230007: "Transport by Another EMS Unit, with a Member of This Crew",
    4230009: "Patient Refused Transport",
    4230011: "Non-Patient Transport (Not Otherwise Listed)",
    4230013: "No Transport",

    // eDisposition.31 - Reason for Refusal/Release
    4231001: "Against Medical Advice",
    4231003: "Patient/Guardian Indicates Ambulance Transport is Not Necessary",
    4231005: "Released Following Protocol Guidelines",
    4231007: "Released to Law Enforcement",
    4231009: "Patient/Guardian States Intent to Transport by Other Means",
    4231011: "DNR",
    4231013: "Medical/Physician Orders for Life Sustaining Treatment",
    4231015: "Other, Not Listed",

    // eDisposition.32 - Level of Care Provided per Protocol
    4232001: "Advanced Life Support (ALS)",
    4232003: "Basic Life Support (BLS)",
    4232005: "Critical Care",
    4232007: "Other",
    4232009: "Specialty Care Transport (SCT)"
};

const NEMSIS_eOutcome_CODES = {
    // eOutcome.01 - Emergency Department Disposition
    4301001: "Discharged to home or self care (routine discharge)",
    4301002: "Discharged/transferred to another short term general hospital for inpatient care",
    4301003: "Discharged/transferred to a skilled nursing facility (SNF)",
    4301004: "Discharged/transferred to an intermediate care facility (ICF)",
    4301005: "Discharged/transferred to another type of institution not defined elsewhere in this code list",
    4301006: "Discharged/transferred to home under care of organized home health service organization in anticipation of covered skills care",
    4301007: "Left against medical advice or discontinued care",
    4301009: "Admitted as an inpatient to this hospital",
    4301020: "Deceased/Expired (or did not recover - Religious Non Medical Health Care Patient)",
    4301021: "Discharged/transferred to court/law enforcement",
    4301030: "Still a patient or expected to return for outpatient services",
    4301043: "Discharged/transferred to a Federal Health Care Facility (e.g., VA or federal health care facility)",
    4301050: "Discharged/transferred to Hospice - home",
    4301051: "Discharged/transferred to Hospice - medical facility",
    4301061: "Discharged/transferred within this institution to a hospital based Medicare approved swing bed",
    4301062: "Discharged/transferred to a inpatient rehabilitation facility including distinct part units of a hospital",
    4301063: "Discharged/transferred to long term care hospitals",
    4301064: "Discharged/transferred to a nursing facility certified under Medicaid but not certified under Medicare",
    4301065: "Discharged/transferred to a psychiatric hospital or psychiatric distinct part unit of a hospital",
    4301066: "Discharged/transferred to a Critical Access Hospital (CAH)",
    4301070: "Discharged/transferred to another type of health care institution not defined elsewhere in the code list",

    // eOutcome.03 - External Report ID/Number Type
    4303001: "Disaster Tag",
    4303003: "Fire Incident Report", 
    4303005: "Hospital-Receiving",
    4303007: "Hospital-Transferring",
    4303009: "Law Enforcement Report",
    4303011: "Other",
    4303013: "Other Registry",
    4303015: "Other Report",
    4303017: "Patient ID",
    4303019: "Prior EMS Patient Care Report",
    4303021: "STEMI Registry",
    4303023: "Stroke Registry",
    4303025: "Trauma Registry"
}

const NEMSIS_eCustomResults_CODES = {
    // eCustomResults.01 - Custom Data Element Result
    7701001: "Not Applicable",
    7701003: "Not Recorded", 
    7701005: "Not Reporting",
    8801001: "Contraindication Noted",
    8801003: "Denied By Order",
    8801005: "Exam Finding Not Present",
    8801007: "Medication Allergy",
    8801009: "Medication Already Taken",
    8801013: "No Known Drug Allergy",
    8801015: "None Reported",
    8801017: "Not Performed by EMS",
    8801019: "Refused",
    8801021: "Unresponsive",
    8801023: "Unable to Complete",
    8801025: "Not Immunized",
    8801027: "Order Criteria Not Met",
    8801029: "Approximate",
    8801031: "Symptom Not Present"
}

const NEMSIS_eOther_CODES = {
    // eOther.01 - Review Requested
    9923001: "No",
    9923003: "Yes",

    // eOther.02 - Potential System of Care/Specialty/Registry Patient
    4502001: "Airway",
    4502003: "Burn", 
    4502005: "Cardiac/MI",
    4502007: "CVA/Stroke",
    4502009: "Drowning",
    4502011: "Other",
    4502013: "Spinal Cord Injury",
    4502015: "STEMI/Acute Cardiac",
    4502017: "Trauma",
    4502019: "Traumatic Brain Injury",

    // eOther.03 - Personal Protective Equipment Used
    4503001: "Eye Protection",
    4503003: "Gloves",
    4503005: "Helmet",
    4503007: "Level A Suit",
    4503009: "Level B Suit", 
    4503011: "Level C Suit",
    4503013: "Level D Suit (Turn out gear)",
    4503015: "Mask-N95",
    4503017: "Mask-Surgical (Non-Fitted)",
    4503019: "Other",
    4503021: "PAPR",
    4503023: "Reflective Vest",

    // eOther.07 - Natural, Suspected, Intentional, or Unintentional Disaster
    4507001: "Biologic Agent",
    4507003: "Building Failure",
    4507005: "Chemical Agent", 
    4507007: "Explosive Device",
    4507009: "Fire",
    4507011: "Hostage Event",
    4507013: "Mass Gathering",
    4507015: "Mass Illness",
    4507017: "Nuclear Agent",
    4507019: "Radioactive Device",
    4507021: "Secondary Destructive Device",
    4507023: "Shooting/Sniper",
    4507025: "Vehicular",
    4507027: "Weather (Other)",
    4507029: "Earthquake",
    4507031: "Flood",
    4507033: "Land Slide", 
    4507035: "Winter Storm",
    4507037: "Tornado",
    4507039: "Hurricane",

    // eOther.09 - External Electronic Document Type
    4509001: "Other Audio Recording",
    4509003: "Billing Information",
    4509005: "Diagnostic Image (CT, X-ray, US, etc.)",
    4509006: "DNR",
    4509008: "Living Will",
    4509009: "ECG/Lab Results",
    4509011: "Guardianship/Power of Attorney",
    4509013: "Other Healthcare Record",
    4509015: "Other",
    4509017: "Patient Identification",
    4509019: "Patient Refusal Sheet",
    4509021: "Other Picture/Graphic",
    4509025: "Other Video/Movie",
    4509027: "ePCR",

    // eOther.12 - Type of Person Signing
    4512001: "EMS Crew Member (Other)",
    4512003: "EMS Primary Care Provider (for this event)",
    4512005: "Healthcare Provider",
    4512007: "Medical Director",
    4512009: "Non-Healthcare Provider",
    4512011: "Online Medical Control Healthcare Practitioner",
    4512013: "Other",
    4512015: "Patient",
    4512017: "Patient Representative",
    4512019: "Witness",

    // eOther.13 - Signature Reason
    4513001: "HIPAA acknowledgement/Release",
    4513003: "Permission to Treat",
    4513005: "Release for Billing",
    4513007: "Transfer of Patient Care",
    4513009: "Refusal of Care",
    4513011: "Controlled Substance, Administration",
    4513013: "Controlled Substance, Waste",
    4513015: "Airway Verification",
    4513017: "Patient Belongings (Receipt)",
    4513019: "Permission to Transport",
    4513021: "Refusal of Transport",
    4513023: "Other",

    // eOther.14 - Type Of Patient Representative
    4514001: "Aunt",
    4514003: "Brother",
    4514005: "Daughter",
    4514007: "Discharge Planner",
    4514009: "Domestic Partner",
    4514011: "Father",
    4514013: "Friend",
    4514015: "Grandfather",
    4514017: "Grandmother",
    4514019: "Guardian",
    4514021: "Husband",
    4514023: "Law Enforcement",
    4514025: "MD/DO",
    4514027: "Mother",
    4514029: "Nurse (RN)",
    4514031: "Nurse Practitioner (NP)",
    4514033: "Other Care Provider (Home health, hospice, etc.)",
    4514035: "Other",
    4514037: "Physician's Assistant (PA)",
    4514039: "Power of Attorney",
    4514041: "Other Relative",
    4514043: "Self",
    4514045: "Sister",
    4514047: "Son",
    4514049: "Uncle",
    4514051: "Wife",

    // eOther.15 - Signature Status
    4515001: "Not Signed - Crew Called out to another call",
    4515003: "Not Signed - Deceased",
    4515005: "Not Signed - Due to Distress Level",
    4515007: "Not Signed - Equipment Failure",
    4515009: "Not Signed - In Law Enforcement Custody",
    4515011: "Not Signed - Language Barrier",
    4515013: "Not Signed - Mental Status/Impaired",
    4515015: "Not Signed - Minor/Child",
    4515017: "Not Signed - Physical Impairment of Extremities",
    4515019: "Not Signed - Refused",
    4515021: "Not Signed - Transferred Care/No Access to Obtain Signature",
    4515023: "Not Signed - Unconscious",
    4515025: "Not Signed -Visually Impaired",
    4515027: "Physical Signature/Paper Copy Obtained",
    4515031: "Signed",
    4515033: "Signed-Not Patient",
    4515035: "Not Signed-Illiterate (Unable to Read)",
    4515037: "Not Signed-Restrained",
    4515039: "Not Signed-Combative or Uncooperative",
}

// Create an array of all code objects
const allCodeObjects = [
    NEMSIS_eCustomConfiguration_CODES,
    NEMSIS_eRecord_CODES,
    NEMSIS_eResponse_CODES,
    NEMSIS_eDispatch_CODES,
    NEMSIS_eCrew_CODES,
    NEMSIS_eTimes_CODES,
    NEMSIS_ePatient_CODES,
    NEMSIS_ePayment_CODES,
    NEMSIS_eScene_CODES,
    NEMSIS_eSituation_CODES,
    NEMSIS_eInjury_CODES,
    NEMSIS_eArrest_CODES,
    NEMSIS_eHistory_CODES,
    NEMSIS_eNarrative_CODES,
    NEMSIS_eVitals_CODES,
    NEMSIS_eLabs_CODES,
    NEMSIS_eExam_CODES,
    NEMSIS_eProtocols_CODES,
    NEMSIS_eMedications_CODES,
    NEMSIS_eAirway_CODES,
    NEMSIS_eDevice_CODES,
    NEMSIS_eDisposition_CODES,
    NEMSIS_eOutcome_CODES,
    NEMSIS_eCustomResults_CODES,
    NEMSIS_eOther_CODES
];

// Combine all objects into a single flat object
const NEMSIS_VALUE_CODES = allCodeObjects.reduce((acc, curr) => {
    return { ...acc, ...curr };
}, {});

module.exports = {
    NEMSIS_VALUE_CODES
};