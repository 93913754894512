import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useParams } from 'react-router-dom';

import Header from '../Header.js';
import ReportFeedbackBox from '../boxes/ReportFeedbackBox.js';
import ReportViewBox from '../boxes/ReportViewBox.js';
import Footer from '../Footer.js';
import './ReportPage.css';

// import '../../../fonts/fonts.css';

const ReportChecks = ({user, reportVisible = true}) => {
    let { reportId } = useParams();
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header user={user} displayHomeButton={true} displayQiButton={false}/>
            
            {reportVisible ? (
                isSmallScreen ? (
                    <div className="single-screen-container">
                        <ReportFeedbackBox user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
                        <div className='box-spacer'/>
                        <ReportViewBox user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
                    </div>
                ) : (
                    <div className="split-screen-container">
                        <div className="split-screen-left">
                            <div className='box-spacer'/>
                            <ReportFeedbackBox user={user} reportId={reportId} isSmallScreen={true}/>
                        </div>
                        <div className="split-screen-right">
                            <div className='box-spacer'/>
                            <ReportViewBox user={user} reportId={reportId} isSmallScreen={true}/>
                        </div>
                    </div>
                )
            ) : (
                <div className="single-screen-container">
                    <ReportFeedbackBox user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
                </div>
            )}
            <Footer sticky={reportVisible} />
        </div>
    );
};

export default ReportChecks;