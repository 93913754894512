import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
import CheckListElement from '../list_elements/CheckListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportChecksModule = ({user, reportId, isSmallScreen}) => {
    const [allChecksData, setAllChecksData] = useState(null);
    const [checkFlagToUpdate, setCheckFlagToUpdate] = useState(null);

    // function that gets one report's time data from the server
    const getOneReportChecks = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_CHECKS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const updateOverriddenStatus = async (checkFlagData, overriddenStatus, objectType) => { // handle flag or check determination here
        
        console.log('objectType status update:', objectType);

        let currentOverriddenStatus = overriddenStatus ? 'overridden' : 'unoverridden';

        console.log('Current Overridden Status being pushed:', currentOverriddenStatus);
        
        let dict = null;
        let url = null;

        // check type here
        if (objectType === 'check') {
            url = URLS.UPDATE_CHECK_OVERRIDE;

            dict = {
                overridden_status: currentOverriddenStatus,
                agency_uid: checkFlagData.reports_agencies_uid,
                report_id: checkFlagData.reports_report_id,
                test_metadata_id: checkFlagData.test_metadata_test_metadata_id,
                CLIENT_ACCESS_KEY: key
            };
        } else if (objectType === 'ai_flag') {
            url = URLS.UPDATE_AI_FLAG_OVERRIDE;

            dict = {
                overridden_status: currentOverriddenStatus,
                // agency_uid: checkFlagData.reports_agencies_uid,
                report_id: checkFlagData.reports_report_id,
                ai_flag_id: checkFlagData.ai_flags_id,
                CLIENT_ACCESS_KEY: key
            };
        }

        console.log('dict:', dict);

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        // console.log("response data:", responseData);
        return responseData;
    };

    useEffect(() => {
        getOneReportChecks(reportId)
            .then((results) => {
                setAllChecksData(results);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (checkFlagToUpdate !== null && checkFlagToUpdate !== undefined) {
            updateOverriddenStatus(
                checkFlagToUpdate.checkFlagData,
                checkFlagToUpdate.overriddenStatus,
                checkFlagToUpdate.objectType
            );
        }
    }, [checkFlagToUpdate]);

    const handleOverrideButtonClick = (checkOrFlag) => {
        // extract json from check
        let checkFlagToUpdate = {
            checkFlagData:checkOrFlag['checkFlagData'],
            overriddenStatus: checkOrFlag['overriddenStatus'],
            objectType: checkOrFlag['objectType']
        };
        // console.log('checkToUpdate (parent, extracted):', checkToUpdate)
        setCheckFlagToUpdate(checkFlagToUpdate);
    };

    const createCheckListElement = (checkData) => {
        return (
            checkData['result'] !== null && checkData['result'] !== 'null' && 
            // (checkData['impact_attribute'] !== 'measure' && checkData['impact_attribute'] !== 'quant-measure' || user.subscriptions.includes('ADMIN_SUB')) ? (
            (checkData['impact_attribute'] !== 'measure' && checkData['impact_attribute'] !== 'quant-measure') ? (
                <CheckListElement
                    checkData={checkData}
                    onButtonClick={handleOverrideButtonClick}
                    />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <h2 className='module-title'> Report Checks </h2>
            
            {/* // if allchecksdata exists and is not null, map over it and create a list element for each check */}
            {allChecksData && allChecksData.length > 0 ? (
                <div>
                    {allChecksData.map((checkData) => (createCheckListElement(checkData)))}
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}

        </div>
    );
}

export default ReportChecksModule;