import React from 'react';
import './Footer.css';

const Footer = ({sticky = false}) => {
    return (
        <div className={`footer ${sticky ? 'footer-sticky' : ''}`}>
            <p>Copyright © Cadmus Health Analytics, Inc. 2025</p>
        </div>
    );
}

export default Footer;