import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import { BrowserRouter as Router, useParams } from 'react-router-dom';

import Header from '../Header.js';
import MedicReportFeedbackBox from '../modules/MedicReportFeedbackBox.js';
import Footer from '../Footer.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const MedicReportEncryptedPage = () => {
    let { reportIdEncrypted } = useParams();
    
    const [reportId, setReportId] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // get report id from encrypted
    const getReportIdFromEncrypted = async (reportIdEncrypted) => {
        const url = URLS.GET_REPORT_ID_FROM_ENCRYPTED;
        const dict = {
            report_id_encrypted: reportIdEncrypted,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status;

            if (Network.checkValidStatusAndResponse(response)) {
                console.log("reportId: " + responseData.report_id);
                setReportId(responseData.report_id);
            } else {
                console.log("Unknown Error");
            }
        });
    }

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (reportIdEncrypted) {
            console.log("reportIdEncrypted: " + reportIdEncrypted);
            getReportIdFromEncrypted(reportIdEncrypted);
        }
    }, [reportIdEncrypted]);

    return (
        <div className="page-container">
            {/* <Header user={null} displayHomeButton={true} displayQiButton={false}/> */}
            <div className='box-spacer'/>
            {reportId && (
                <MedicReportFeedbackBox reportId={reportId} isSmallScreen={isSmallScreen} />
            )}
            <Footer />
        </div>
    );
};

export default MedicReportEncryptedPage; 