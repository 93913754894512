import { motion } from "framer-motion"
import "./LandingAnimation.css"

const config = {
    frame: {
        width: 650,
        height: 400,
        viewBox: "0 0 650 400"
    },
    rect: {
        base: {
            width: 100,
            height: 380,
            rx: 10,
            stroke: "var(--blue)"
        },
        white: {
            width: 90, // 140 - 10
            height: 370, // 500 - 10
            rx: 5, // 10 - 5
            fill: "white"
        }
    },
    animation: {
        rotations: [0, 60, 120],
        transition: { type: "spring", delay: 1.8, stiffness: 400, damping: 40, mass: 1 }
    },
    styles: {
        container: {
            position: 'relative',
            width: 650,
            height: 400
        },
        rotatingDiv: {
            position: 'absolute',
            width: '100%',
            height: '100%'
        },
        svg: {
            position: 'absolute',
            top: 0,
            left: 0
        }
    }
}

const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
        const delay = i * 0.5
        return {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
                opacity: { delay, duration: 0.01 },
            },
        }
    },
}

// Calculate centers based on config
const centers = {
    rect: {
        x: config.frame.width / 2 - config.rect.base.width / 2,
        y: config.frame.height / 2 - config.rect.base.height / 2
    },
    whiteRect: {
        x: config.frame.width / 2 - config.rect.white.width / 2,
        y: config.frame.height / 2 - config.rect.white.height / 2
    }
}

const StarOfLife = () => {
    return (
        <motion.div style={config.styles.container}>
            {/* Render all base SVG rectangles first */}
            {config.animation.rotations.map((rotation) => (
                <motion.div
                    key={`base-${rotation}`}
                    style={config.styles.rotatingDiv}
                    animate={{ rotate: rotation }}
                    transition={config.animation.transition}
                >
                    {createSvgRectangle()}
                </motion.div>
            ))}
            
            {/* Render all white rectangles on top */}
            {config.animation.rotations.map((rotation) => (
                <motion.div
                    key={`white-${rotation}`}
                    style={config.styles.rotatingDiv}
                    animate={{ rotate: rotation }}
                    transition={config.animation.transition}
                >
                    {createWhiteRectangle()}
                </motion.div>
            ))}
            {TestSnake()}
        </motion.div>
    );
}

const createWhiteRectangle = () => {
    return (
        <motion.svg
            width={config.frame.width}
            height={config.frame.height}
            viewBox={config.frame.viewBox}
            style={{ ...config.styles.svg }}
        >
            <motion.rect
                width={config.rect.white.width}
                height={config.rect.white.height}
                x={centers.whiteRect.x}
                y={centers.whiteRect.y}
                rx={config.rect.white.rx}
                fill={config.rect.white.fill}
            />
        </motion.svg>
    );
}

const createSvgRectangle = () => {
    return (
        <motion.svg
            width={config.frame.width}
            height={config.frame.height}
            viewBox={config.frame.viewBox}
            initial="hidden"
            animate="visible"
            className="landing-animation"
            style={{ ...config.styles.svg }}
        >
            <motion.rect
                className="landing-animation-shape"
                width={config.rect.base.width}
                height={config.rect.base.height}
                x={centers.rect.x}
                y={centers.rect.y}
                rx={config.rect.base.rx}
                stroke={config.rect.base.stroke}
                variants={draw}
                custom={1}
            />
        </motion.svg>
    );
}

const extractPathCoordinates = (pathString) => {
    // Match all coordinate pairs (number number) in the path string
    const coordRegex = /(\d+\.?\d*)\s+(\d+\.?\d*)/g;
    const matches = [...pathString.matchAll(coordRegex)];
    
    // Convert matches to coordinate objects
    return matches.map(match => ({
        x: parseFloat(match[1]),
        y: parseFloat(match[2])
    }));
};

const TestSnake = () => {
    const pathString = "M 325 340 C 313 150 308 110 311 90 C 318 70 332 70 339 90 C 342 110 337 150 325 340 C 300 315 345 300 345 280 C 345 260 300 255 300 240 C 300 220 355 215 355 200 C 355 180 290 175 290 155 C 290 130 350 125 360 140"
    const coordinates = extractPathCoordinates(pathString);

    return (
        <div style={{ position: "relative", width: "650px", height: "400px" }}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="650" 
                height="400"
                style={{ position: "absolute", top: 0, left: 0 }}
            >
                <motion.path
                    d={pathString}
                    fill="transparent"
                    strokeWidth="12"
                    stroke="var(--blue)"
                    strokeLinecap="round"
                    initial={{ pathLength: 0.001, opacity: 0 }}
                    animate={{ pathLength: 1, opacity: 1 }}
                    transition={{
                        pathLength: {
                            delay: 2.2,
                            type: "tween",
                            duration: 1.1,
                            ease: "easeOut"
                        },
                        opacity: { 
                            duration: 0.01, 
                            delay: 2.2
                        }
                    }}
                />
                
                {/* Uncomment to see points at path coordinates */}
                {/* {coordinates.map((coord, index) => (
                    <circle 
                        key={index}
                        cx={coord.x}
                        cy={coord.y}
                        r="4"
                        fill="red"
                    />
                ))} */}
            </svg>
        </div>
    );
}

// export default TestSnake;
export default StarOfLife;