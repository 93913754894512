class User {
    constructor(uid, email, owner_name, agency_name, subscriptions, registered_timestamp, last_login_timestamp, access_token) {
        this.uid = uid;
        this.email = email;
        this.ownerName = owner_name;
        this.agencyName = agency_name;
        this.subscriptions = subscriptions;
        this.registeredTimestamp = registered_timestamp;
        this.lastLoginTimestamp = last_login_timestamp;
        this.accessToken = access_token;
        this.browseState = { // initial state of home page data
            currentPage: 0,
            reportsPerPage: 20,
            filterState: {
                tagTypes: [],
                medics: [],
                date: ["all"],
                read: ["both"],
                finished: ["unfinished"]
            }
        }
    }

    static createFromJSON(responseJSON) {
        const uid = responseJSON.uid;
        const email = responseJSON.email;
        const ownerName = responseJSON.owner_name;
        const agencyName = responseJSON.agency_name;
        const subscriptions = responseJSON.subscription_types;
        const registeredTimestamp = responseJSON.registered_timestamp;
        const lastLoginTimestamp = responseJSON.last_login_timestamp;
        const accessToken = responseJSON.access_token;

        return new User(uid, email, ownerName, agencyName, subscriptions, registeredTimestamp, lastLoginTimestamp, accessToken);
    }
}

export default User;
