import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import DualColListElement from '../list_elements/DualColListElement.js';
import './Module.css';
import TypeTag from '../tags/TypeTag.js';
import MedicTag from '../tags/MedicTag.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const bypass = process.env.REACT_APP_BYPASS_LOGIN;
const URLS = require("../../../urls.js");

// Load NEMSIS codes once
const { NEMSIS_KEY_CODES } = require("../../../models/nemsis_key_codes.js")

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const convertNemsisCodesToKeys = (obj) => {
    if (!obj || typeof obj !== 'object') {
        return obj;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
        return obj.map(item => {
            // Recursively process array items
            return convertNemsisCodesToKeys(item);
        });
    }

    // Handle objects
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
        // Check if the key is a NEMSIS code that needs conversion
        const newKey = NEMSIS_KEY_CODES[key] || key;
        
        if (typeof value === 'object') {
            // Recursively process nested objects
            result[newKey] = convertNemsisCodesToKeys(value);
        } else {
            // Keep the value but use converted key
            result[newKey] = value;
        }
    }
    return result;
}

const ReportInfoModule = ({user, reportId, isSmallScreen, showStatus = true}) => {
    const [reportData, setReportData] = useState(null);
    const [reportTypes, setReportTypes] = useState(null);
    const [medics, setMedics] = useState(null);
    const [readStatus, setReadStatus] = useState(null);
    const [finishedStatus, setFinishedStatus] = useState(null);

    const handleReadButtonClick = () => {
        console.log('readStatus changed');
        if (readStatus) {
            setReadStatus(0);
        } else {
            setReadStatus(1);
        }
    };

    const handleFinishedButtonClick = () => {
        console.log('finishedStatus changed');
        if (finishedStatus) {
            setFinishedStatus(0);
        } else {
            setFinishedStatus(1);
        }
    };

    const updateReadFinishedStatus = async (reportId, readStatus, finishedStatus) => {
        const url = URLS.UPDATE_REPORT_STATUS;

        let currentReadStatus = readStatus ? 'read' : 'unread';
        let currentFinishedStatus = finishedStatus ? 'finished' : 'unfinished';

        const dict = {
            // uid: user.uid,
            report_id: reportId,
            read_status: currentReadStatus,
            finished_status: currentFinishedStatus,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // function that gets one reports from the server
    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            // uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                setReportData(data);
                setReportTypes(data[0].report_type.split(','));
                setMedics(JSON.parse(data[0].medics));
                setFinishedStatus(data[0].report_marked_finished);
                if (data[0].report_marked_read === 0) {
                    if (bypass !== 'true') {
                        setReadStatus(1);
                    } else {
                        setReadStatus(0);
                    }
                } else {
                    setReadStatus(1);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (readStatus !== null && finishedStatus !== null) {
            // console.log('Read Status:', readStatus);
            // console.log('Finished Status:', finishedStatus);
            updateReadFinishedStatus(reportId, readStatus, finishedStatus);
        }
    }, [readStatus, finishedStatus]);

    const createInfoListElement = (elementName, elementValue) => {
        return (
            elementValue !== null && elementValue !== 'null' ? ( // if elementValue is null, display 'insufficient data'
            <DualColListElement
            elementName={elementName}
            elementValue={elementValue}   
            />
            ) : (
            <DualColListElement
            elementName={elementName}
            elementValue='Insufficient Data'
            />
            )
        );
    };

    const convertToLocaleString = (dateString) => {
        const options = { 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true, 
            year: 'numeric', 
            month: 'numeric', 
            day: 'numeric'
        };
    
        // if datestring is undefined or null, return 'no data'
        if (dateString === undefined || dateString === null) {
            return 'no data';
        }

        // Print out unconverted date
        // console.log('original dateString:', dateString);
    
        // convert the date string to a valid ISO 8601 date string and add Z to the end to indicate UTC time
        dateString = dateString.replace(' ', 'T');
        dateString = dateString + 'Z';

        // now convert the date string (which represnets a date in GMT) to a date object
        const date = new Date(dateString);
        
        // Print new date
        // console.log('new date:', date); // this is printing out GMT as CDT
    
        return date.toLocaleString([], options);
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Report Information</h2>
            </div>
            {reportData && reportTypes && medics ? (
                <div>
                    {showStatus && (
                        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                            <button className="enter-button" style={{marginBottom: '10px'}} onClick={() => handleReadButtonClick(reportId, readStatus)}>
                                {readStatus ? 'Report is Read' : 'Report is Unread'}
                            </button>
                            <button className="enter-button" style={{marginBottom: '10px'}} onClick={() => handleFinishedButtonClick(reportId, finishedStatus)}>
                                {finishedStatus ? 'Report is Finished' : 'Report is Unfinished'}
                            </button>
                        </div>
                    )}
                    {createInfoListElement('Incident #:', reportData[0].incident_number)}
                    {createInfoListElement('Run Began:', convertToLocaleString(reportData[0].call_begin))}
                    {createInfoListElement('Run Ended, Back in Service:', convertToLocaleString(reportData[0].unit_back_in_service))}
                    {createInfoListElement('PCR Completed:', convertToLocaleString(reportData[0].creation_timestamp))}
                    
                    <div className='list-element'>
                        <div className='small-div'>
                            <strong>Involved Medics:</strong>
                        </div>
                        <div className='small-div' style={{flexDirection: 'row'}}>
                            {medics.map((medic) => (
                                <MedicTag medicNumber={medic.medic_name || medic.crew_member_id} isAuthor={medic.author} isButton={false}/>
                            ))}
                        </div>
                    </div>

                    <div className='list-element'>
                        <div className='small-div'>
                            <strong>Report Type(s):</strong>
                        </div>
                        <div className='small-div' style={{flexDirection: 'row'}}>
                            {reportTypes.map((type) => (
                                <TypeTag reportType={type} isButton={false}/>
                            ))}
                        </div>
                    </div>
                    {/* <div style={{textAlign: 'left'}}>
                        <strong>Report JSON:</strong>
                        <pre>
                            {(() => {
                                try {
                                    return JSON.stringify(convertNemsisCodesToKeys(JSON.parse(reportData[0].report_json)), null, 2);
                                    // return typeof JSON.parse(reportData[0].report_json);

                                    // return reportData[0].report_json;
                                } catch (e) {
                                    return 'JSON invalid';
                                }
                            })()}
                        </pre>
                    </div> */}
                </div>
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportInfoModule;