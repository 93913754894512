import React, { act, useEffect, useState } from 'react';
import './FilterListElement.css';

import FilterDropdown from './FilterListElement/FilterDropdown';

import TypeTag from "../tags/TypeTag";
import MedicTag from "../tags/MedicTag";
import { filter } from 'd3';

const tagNames = {
    "non-emergent": "Non-Emergent",
    "non-acute": "Non-Acute/Other",
    "refusal": "Refusal",
    "cancelled": "Cancelled/No Patient",
    "general-emergent": "Emergent",
    "stroke": "Stroke",
    "arrest": "Arrest",
    "stemi": "STEMI",
    "trauma": "Trauma",
    "respiratory": "Resp",
    "seizure": "Seizure",
    "syncope": "Syncope",
    "tbi": "TBI",
    "pediatrics": "Ped",
    "hypoglycemia": "LBG",
    "airway": "Airway",
    "asthma": "Asthma",
    "ai-flag": "Flag",
    // "narrative-suggestions": "NarSug",
    "suggestions": "NewSug",
    "protocol-flag": "Protocol"
};
const sortedTagNames = Object.fromEntries(
    Object.entries(tagNames).sort()
);

const medicNames = {
    "2NF2HCLSNI": "Alex",
    "SDJ205NDG9": "Barry",
    "DK205LKFGJ": "Coral"
};

const dateOptions = {
    "all": "All Time",
    "day": "Past Day",
    "week": "Past Week",
    "month": "Past Month",
    "year": "Past Year",
    "five-years": "Past 5 Years",
    "custom": "Custom",
}

const measureOptions = {
    // general-emergent
    "GA2-GeneralEmergent-01": "GA2-GeneralEmergent-01",
    "GA2-GeneralEmergent-02": "GA2-GeneralEmergent-02",
    "GA2-GeneralEmergent-03": "GA2-GeneralEmergent-03",
    "GA2-GeneralEmergent-04": "GA2-GeneralEmergent-04",
    "GA2-GeneralEmergent-05": "GA2-GeneralEmergent-05",
    "GA2-GeneralEmergent-06": "GA2-GeneralEmergent-06",
    "GA2-GeneralEmergent-07": "GA2-GeneralEmergent-07",
    "GA2-GeneralEmergent-08": "GA2-GeneralEmergent-08",
    // "GA2-GeneralEmergent-09": "GA2-GeneralEmergent-09", // disabled/broken
    // "GA2-GeneralEmergent-10": "GA2-GeneralEmergent-10", // disabled/broken
    // trauma
    "GA2-Trauma-01": "GA2-Trauma-01",
    "GA2-Trauma-02": "GA2-Trauma-02",
    "GA2-Trauma-03": "GA2-Trauma-03",
    // arrest
    "GA2-Arrest-01": "GA2-Arrest-01",
    // "GA2-Arrest-02": "GA2-Arrest-02", // DELETED
    "GA2-Arrest-03": "GA2-Arrest-03",
    "GA2-Arrest-04": "GA2-Arrest-04",
    "GA2-Arrest-05": "GA2-Arrest-05",
    "GA2-Arrest-06": "GA2-Arrest-06",
    // "GA2-Arrest-07": "GA2-Arrest-07", // disabled
    "GA2-Arrest-08": "GA2-Arrest-08",
    // asthma
    "GA2-Asthma-01": "GA2-Asthma-01",
    // hypoglycemia
    "GA2-Hypoglycemia-01": "GA2-Hypoglycemia-01",
    // respiratory
    // "GA2-Respiratory-01": "GA2-Respiratory-01", // disabled/broken
    // "GA2-Respiratory-02": "GA2-Respiratory-02", // DELETED
    "GA2-Respiratory-03": "GA2-Respiratory-03",
    // "GA2-Respiratory-04": "GA2-Respiratory-04", // disabled
    "GA2-Respiratory-05": "GA2-Respiratory-05",
    // stroke
    "GA2-Stroke-01": "GA2-Stroke-01",
    // "GA2-Stroke-02": "GA2-Stroke-02", // disabled
    "GA2-Stroke-03": "GA2-Stroke-03",
    "GA2-Stroke-04": "GA2-Stroke-04",
    // "GA2-Stroke-05": "GA2-Stroke-05", // disabled
    // "GA2-Stroke-06": "GA2-Stroke-06", // disabled
    // STEMI
    // "GA2-STEMI-01": "GA2-STEMI-01", // disabled
    "GA2-STEMI-02": "GA2-STEMI-02",
    "GA2-STEMI-03": "GA2-STEMI-03",
    "GA2-STEMI-04": "GA2-STEMI-04",
    // "GA2-STEMI-05": "GA2-STEMI-05", // disabled
    // seizure
    "GA2-Seizure-01": "GA2-Seizure-01",
    "GA2-Seizure-02": "GA2-Seizure-02",
    // other
    // "At Least 2 Sets of Vitals Taken": "At Least 2 Sets of Vitals",
    // "Run Times Recorded": "Run Times Recorded",
    // "Odometer Readings Recorded": "Odo Readings Recorded",
    // "Narrative": "Narrative",
    // "Exam Administered": "Exam Administered",
    // "ECG for Systolic Blood Pressure out of Bounds": "ECG for BP out of Bounds"
}

const readOptions = { 
    "read": "Read",
    "unread": "Unread",
    "both": "Read & Unread"
}

const finishedOptions = {
    "finished": "Finished",
    "unfinished": "Unfinished",
    "both": "Finished & Unfinished"
}

let filterDict = {}; // delete later

const FilterListElement = ({ medicsData, filterState, dictUpdateHandler, stateUpdateHandler, filterFields }) => {
    // Initialize medicsData and create medicsOptions
    medicsData = Array.isArray(medicsData) ? medicsData : [];
    const medicsOptions = {};
    for (const medic of medicsData) {
        if (medic["crew_member_id"] && medic["medic_name"]) {
            medicsOptions[medic["crew_member_id"]] = medic["medic_name"];
        }
    }
    const sortedMedicsOptions = new Map(
        Object.entries(medicsOptions).sort((a, b) => a[1].localeCompare(b[1]))
    );

    // Set initial filter state conditionally
    const [tagTypes, setTagTypes] = useState(filterState.tagTypes || []);
    const [medics, setMedics] = useState(filterState.medics || []);
    const [date, setDate] = useState(filterState.date || ["all"]);
    const [measure, setMeasure] = useState(filterState.measure || []);
    const [read, setRead] = useState(filterState.read || ["both"]);
    const [finished, setFinished] = useState(filterState.finished || ["unfinished"]);

    // State for applied filters
    const [appliedTags, setAppliedTags] = useState(filterDict["reportType"] ? filterDict["reportType"].split(",") : []);
    const [appliedMedics, setAppliedMedics] = useState(filterDict["crewMemberId"] ? filterDict["crewMemberId"].split(",") : []);
    
    // Variable to control all dropdowns
    const [dropdown, setDropdown] = useState(null);

    const handleApplyFilters = () => {
        let newFilterDict = {};
        newFilterDict.readStatus = convertBooleans(read[0]);   
        newFilterDict.finishedStatus = convertBooleans(finished[0]);
        newFilterDict.reportType = tagTypes.join(",") || null; // || null added, perhaps not needed
        newFilterDict.crewMemberId = medics.join(",") || null;
        let dateRange = convertDate(date[0]); // date is in an array length 1
        newFilterDict.lowerDate = dateRange[0] ? formatDateToString(dateRange[0]) : null;
        newFilterDict.upperDate = dateRange[1] ? formatDateToString(dateRange[1]) : null;  
        newFilterDict.measureName = measure[0] || null;

        // console.log("newFilterDict", newFilterDict);
        
        dictUpdateHandler(newFilterDict);

        if (stateUpdateHandler) {
            stateUpdateHandler({tagTypes, medics, date, measure, read, finished});
        }

        setAppliedTags(tagTypes);
        setAppliedMedics(medics);

        // reset dropdowns
        setDropdown(!dropdown);
    }

    const convertBooleans = (value) => {
        //convert from string values (needed for dictionary keys) too booleans to return
        if (value === "both") {
            return null;
        } else if (value === "read" || value === "finished") {
            return true;
        } 
        return false;
    }

    const convertDate = (range) => {
        const curr = new Date();
        var dateRange = [new Date(0), curr];
        if (range === "all") {
            return dateRange;
        } else if (range === "day") {
            dateRange[0] = new Date(curr.getTime() - (24 * 60 * 60 * 1000)) // subtract 24hrs from today
        } else if (range === "week") {
            dateRange[0] = new Date(curr.getTime() - (7 * 24 * 60 * 60 * 1000)) // subtract 7 days from today
        } else if (range === "month") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCMonth(curr.getUTCMonth() - 1);
            // Handle month overflow if necessary, ie Feb 31
            if (dateRange[0].getUTCMonth() === curr.getUTCMonth()) {
                dateRange[0].setUTCDate(0); // set to last day of previous month
            }
        } else if (range === "year") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCFullYear(curr.getUTCFullYear() - 1);
        } else if (range === "five-years") {
            dateRange[0] = new Date(curr);
            dateRange[0].setUTCFullYear(curr.getUTCFullYear() - 5);
        }
        return dateRange;
    }

    function formatDateToString(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        if (filterState) {
            handleApplyFilters();
        }
    }, []);

    return (
        <div className="list-element filter-list-element">
            <div className='row'>
                <div className='filters'>
                    {filterFields === 'week_review' || filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Type Tags" 
                            options={sortedTagNames} 
                            selected={tagTypes} 
                            updateSelected={setTagTypes} 
                            multiple={true}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Medics" 
                            options={sortedMedicsOptions} 
                            selected={medics} 
                            updateSelected={setMedics} 
                            multiple={true}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'week_review' || filterFields === 'analytics' ? (
                        <FilterDropdown 
                            title="Date Range" 
                            options={dateOptions} 
                            selected={date}
                            updateSelected={setDate} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'analytics' ? (
                        <FilterDropdown 
                            title="Measures" 
                            options={measureOptions} 
                            selected={measure}
                            updateSelected={setMeasure}
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Read" 
                            options={readOptions} 
                            selected={read}
                            updateSelected={setRead} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                    {filterFields === 'reportsList' ? (
                        <FilterDropdown 
                            title="Finished" 
                            options={finishedOptions} 
                            selected={finished}
                            updateSelected={setFinished} 
                            multiple={false}
                            dropdown={dropdown}
                        />
                    ) : null}
                </div>
                <button className="submit" onClick={handleApplyFilters}>
                    {/* Apply <br/> Filters */}
                    <i className="only-i fa-solid fa-filter"></i>
                </button>
                {/* <i className="fa-solid fa-envelope"></i> */}
            </div>
            
            {filterFields === 'week_review' || filterFields === 'reportsList' ? (
                <>
                    {appliedTags.length > 0 && (
                        <div className='row'>
                            <div className='applied-tags'>
                                <div className='tags'>
                                {appliedTags.map((tag) => (
                                    <TypeTag reportType={tag} isButton={false} />
                                ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {appliedMedics.length > 0 && (
                    <div className='row'>
                        <div className='applied-tags'>
                            <div className='tags'>
                                {appliedMedics.map((medic) => (
                                    <MedicTag medicNumber={sortedMedicsOptions.get(medic)} isButton={false}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default FilterListElement;