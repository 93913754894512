import React from 'react';
import MedicInfoModule from '../modules/MedicInfoModule.js';
import './Box.css';

const MedicInfoBox = ({ user, isSmallScreen }) => {

    return (
        <div className={isSmallScreen ? "standard-box-small" : "standard-box"}>
            
            <h1 className='box-title'> Medic Information </h1>

            <MedicInfoModule 
                user={user}
                isSmallScreen={isSmallScreen}
            />
        </div>
    );
};

export default MedicInfoBox; 