import React, { useState } from 'react';
import Network from '../../../models/Network.js';

import './CheckListElement.css';

import TypeTag from '../tags/TypeTag.js';
import MedicTag from '../tags/MedicTag.js';
import ImpactAttributeTag from '../tags/ImpactAttributeTag.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const SuggestionListElement = ({ suggestionData, onButtonClick, showFeedbackInput = false, onInputChange }) => {
    const [ratingStatus, setRatingStatus] = useState(suggestionData.rating);
    const [userInput, setUserInput] = useState(suggestionData.medic_text || '');

    const handleAcceptClick = () => {
        setStatusAndSendToParent(1)
    };

    const handleRejectClick = () => {
        setStatusAndSendToParent(2)
    };

    const handleMisleadingClick = () => {
        setStatusAndSendToParent(3)
    };

    const setStatusAndSendToParent = (newRating) => {
        // Pass updated status to parent component
        setRatingStatus(currentRating => {

            // Pass updated status to parent component
            let suggestionToUpdate = {
                suggestionData: suggestionData,
                ratingStatus: newRating,
                objectType: 'suggestion'
            };
            // console.log('checkToUpdate (child):', checkToUpdate);
            onButtonClick(suggestionToUpdate);
            console.log('suggestion rating changed from: ', currentRating, ' to ', newRating);
            return newRating;
        });
    };

    const handleButtonClick = () => {
        // Use the functional form of setState
        setRatingStatus(prevStatus => {
            const newStatus = 'this';
    
            // Pass updated status to parent component
            let suggestionToUpdate = {
                suggestionData: suggestionData,
                ratingStatus: newStatus,
                objectType: 'suggestion'
            };
            // console.log('checkToUpdate (child):', checkToUpdate);
            onButtonClick(suggestionToUpdate);
    
            return newStatus;
        });
    
        console.log('suggestion rating changed');
    };
    
    const handleInputChange = (e) => {
        const newInput = e.target.value;
        setUserInput(newInput);
        onInputChange(suggestionData.suggestions_id, newInput);
    };

    return (
        <div className="list-element" style={{ backgroundColor: '#fad4d4' }}>
            <div className="small-div" style={{ display: 'flex', flexDirection: 'column'}}>
                <div className='large-div' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={0 === 0 ? 'medium-div' : 'small-div'}>
                        <TypeTag reportType={suggestionData.report_type} isButton={false}/>
                        <ImpactAttributeTag attributeType={'documentation'} isButton={false}/>
                    </div>
                    <div className="large-div" style={{ justifyContent: 'center' }}>
                        <div className="element-name" style={{ fontSize: 'larger' }}> Narrative Suggestion #{suggestionData.suggestion_number} </div>
                    </div>
                    {!showFeedbackInput ? (
                        <div className="medium-div" style={{ justifyContent: 'right' }}>
                            <button className={ratingStatus == 1 ? "rating-button-sel" : "rating-button"} onClick={handleAcceptClick}>{'Accept'}</button>
                            <button className={ratingStatus == 2 ? "rating-button-sel" : "rating-button"} onClick={handleRejectClick}>{'Reject'}</button>
                            <button className={ratingStatus == 3 ? "rating-button-sel" : "rating-button"} onClick={handleMisleadingClick}>{'Misleading'}</button>
                        </div>
                    ) : (
                        <div className="medium-div" style={{ justifyContent: 'right' }}></div>
                    )}
                </div>
                <>
                    <div className="small-div" style={{ marginTop: 20 }}>
                        <p><strong><u>Suggestion</u>:</strong> {suggestionData.feedback_text}</p>
                    </div>
                    {suggestionData.context_table && (
                        <div className="small-div" style={{ marginTop: 20 }}>
                            <p><strong><u>Documentation Reference</u>:</strong> {suggestionData.context_table}, Row {suggestionData.context_row}</p>
                        </div>
                    )}
                    {suggestionData.feedback_justification && (
                        <div className="small-div" style={{ marginTop: 20 }}>
                            <p><strong><u>Justification</u>:</strong> {suggestionData.feedback_justification}</p>
                        </div>
                    )}
                    {showFeedbackInput && (
                        <div className="input-section" style={{ marginTop: '20px' }}>
                            <textarea
                                value={userInput}
                                onChange={handleInputChange}
                                placeholder="Write here to correct your narrative - if you cannot correct, leave this box blank"
                                style={{
                                    width: '100%',
                                    minHeight: '72px',
                                    padding: '10px',
                                    marginBottom: '10px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    backgroundColor: suggestionData.medic_text ? 'rgb(212, 250, 212)' : 'white',
                                    resize: 'none'
                                }}
                            />
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default SuggestionListElement;