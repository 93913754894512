import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Module.css';

const GoToMedicViewModule = ({ reportId, isSmallScreen }) => {
    const navigate = useNavigate();

    const handleGoToMedicView = () => {
        navigate(`/medic-report/${reportId}`);
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            {/* <div>
                <h2 className='module-title'>Medic View</h2>
            </div> */}
            <button 
                className="enter-button" 
                onClick={handleGoToMedicView}
            >
                Go to Medic View
            </button>
        </div>
    );
};

export default GoToMedicViewModule; 