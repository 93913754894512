import React from 'react';

import '../modules/Module.css';
import ReportInfoModule from './ReportInfoModule';
import MedicReportAISuggestionsModule from './MedicReportFeedbackModule';

const MedicReportFeedbackBox = ({ user, reportId, isSmallScreen }) => {
    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>
            <h1 className='box-title'> Report Feedback (medic) </h1>
            <ReportInfoModule reportId={reportId} isSmallScreen={isSmallScreen} showStatus={false}/>
            <MedicReportAISuggestionsModule reportId={reportId} isSmallScreen={isSmallScreen}/>
        </div>
    );
};

export default MedicReportFeedbackBox; 