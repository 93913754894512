import React from 'react';
import { motion } from "framer-motion"
import './ListElement.css';

const LoadingListElement = ({ height = 100, backgroundColor = null }) => {
    const circleSize = Math.min(height * 0.4, 40); // Circle size scales with height but caps at 40px
    
    return (
        <div className="list-element" style={{ 
            height: height, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            backgroundColor: backgroundColor || 'var(--light-grey)'
        }}>
            <motion.svg
                width={circleSize}
                height={circleSize}
                viewBox="0 0 50 50"
                style={{ overflow: 'visible' }}
            >
                <motion.circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="var(--blue)"
                    strokeWidth="4"
                    fill="transparent"
                    animate={{
                        rotate: [0, 360],
                        strokeDasharray: ["1, 150", "90, 150", "90, 150", "1, 150"],
                        strokeDashoffset: [0, -35, -124, -159]
                    }}
                    transition={{
                        duration: 2,
                        ease: "linear",
                        repeat: Infinity,
                        repeatType: "loop"
                    }}
                />
            </motion.svg>
        </div>
    );
};

export default LoadingListElement;