import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Network from '../../../models/Network.js';

import ReportListElement from '../list_elements/ReportListElement.js';
import FilterListElement from '../list_elements/FilterListElement.js';
import LoadingListElement from '../list_elements/LoadingListElement.js';
import './Module.css';
import { filter } from 'd3';
const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const bypass = process.env.REACT_APP_BYPASS_LOGIN;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportsListFilteredModule = ({ user, isSmallScreen}) => {
    const navigate = useNavigate();

    const [jsonData, setJsonData] = useState(null);
    const [medicsData, setMedicsData] = useState(null);
    const [filterDict, setFilterDict] = useState(null);
    const [filterState, setFilterState] = useState(user.browseState.filterState);
    const [currentPage, setCurrentPage] = useState(user.browseState.currentPage);
    const [reportsPerPage, setReportsPerPage] = useState(user.browseState.reportsPerPage);

    const handleNextPageButtonClick = () => {
        setCurrentPage(currentPage + 1)
    }

    const handlePrevPageButtonClick = () => {
        setCurrentPage(currentPage - 1)
    }

    // function that gets multiple reports from the server
    const getSeveralReportsFiltered = async (desiredNumber, reportOffset, filterDict) => {

        const url = URLS.GET_SEVERAL_REPORTS_FILTERED;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            filter_dict: filterDict,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    const getAllAgencyMedics = async (agencyId) => {
        const url = URLS.GET_MEDICS_FOR_USER;
        const dict = {
            uid: user.uid,
            agency_id: agencyId,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    const markReportRead = async (reportId) => {
        
        // const url = URLS.GET_SEVERAL_REPORTS;
        const url = URLS.MARK_REPORT_READ_UNREAD;

        const dict = {
            uid: user.uid,
            report_id: reportId,
            read_status: 'read',
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // update browseState
    useEffect(() => {
        const isFilterStateChanged = Object.keys(filterState).some(key => filterState[key] !== user.browseState.filterState[key]);
        // console.log('isFilterStateChanged:', isFilterStateChanged);

        if (isFilterStateChanged) {
            setCurrentPage(0);
        }

        // update the state of user.browseState
        user.browseState.filterState = filterState;
        user.browseState.currentPage = currentPage;
        // user.browseState.reportsPerPage = reportsPerPage; // does not update currently
        
    }, [reportsPerPage, currentPage, filterState]);

    // get reports from the server
    useEffect(() => {
        if (filterDict) {
            getSeveralReportsFiltered(reportsPerPage, currentPage*reportsPerPage, filterDict)
                .then((data) => {
                    console.log('Setting new JSON data:', data);
                    setJsonData(data);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
        }
    }, [filterDict, currentPage, reportsPerPage]);

    // get all medics from the server
    useEffect(() => {
        getAllAgencyMedics(user.uid)
            .then((data) => {
                setMedicsData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    const handleReportButtonClick = (reportId) => {
        console.log("Report button pressed");
        if (bypass !== 'true') { // do not mark as read in dev mode
            // mark the report as read
            markReportRead(reportId);
        }
        navigate('/report/' + reportId);
    };

    const createReportListElement = (reportId, reportNumber, incidentNumber, numberOfIssues, numberOfSuggestions, reportType, incidentTime, read, finished) => {
        // if numberOfIssues is null, set it to 0
        if (numberOfIssues === null) {
            numberOfIssues = '-';
        }
        if (numberOfSuggestions === null) {
            numberOfSuggestions = '-';
        }
        return (
            <ReportListElement
                // key={reportNumber} // React 'needs' a unique key to differentiate between elements - causing page progression bug
                reportNumber={reportNumber}
                incidentNumber={incidentNumber}
                numberOfIssues={numberOfIssues}
                numberOfSuggestions={numberOfSuggestions}
                reportType={reportType}
                incidentTime={incidentTime}
                read={read}
                finished={finished}
                onButtonClick={() => handleReportButtonClick(reportId)} // Pass the event handler to the component
            />
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>

            <FilterListElement
                medicsData={medicsData}
                filterState={filterState}
                dictUpdateHandler={setFilterDict}
                stateUpdateHandler={setFilterState}
                filterFields="reportsList"
            />
            <div className="list-header">
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Incident # </div>
                </div>
                <div className='tiny-div'>
                    <div style={{fontWeight: "bold"}}><i className="fa-solid fa-exclamation"></i><i className="fa-solid fa-exclamation"></i><i className="fa-solid fa-exclamation"></i></div>
                </div>
                <div className='tiny-div'>
                    <div style={{fontWeight: "bold"}}><i className="fa-solid fa-message"></i></div>
                </div>
                <div className='large-div'>
                    <div style={{fontWeight: "bold"}}> Run Type(s) </div>
                </div>
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Run Ended </div>
                </div>
                <button className="invisible-button"> View Report </button>
            </div>

            {jsonData ? (
                    jsonData.map((data) => createReportListElement(data.report_id, data.pcr_number, data.incident_number, data.number_issues,
                    data.number_suggestions, data.report_type, data.unit_back_in_service, data.report_marked_read, data.report_marked_finished))
                ) : (
                    <LoadingListElement height={100} backgroundColor="white"/>
                )}

            {/* <div style={{ justifyContent: 'center' }}>
                <p>{reportsPerPage} Reports per Page</p>
            </div> */}

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto', width: '40%', marginBottom: '20px'}}>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    {jsonData && currentPage > 0 ?
                        <button className="box-button" onClick={() => handlePrevPageButtonClick()}>Prev Page</button>
                    : null}
                </div>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    <p>Page {currentPage + 1}</p>
                </div>
                <div className='small-div' style={{justifyContent: 'center'}}>
                    {jsonData && jsonData.length === reportsPerPage ?
                        <button className="box-button" onClick={() => handleNextPageButtonClick()}>Next Page</button>
                    : 'no more reports'}
                </div>
            </div>

        </div>
    );
}

export default ReportsListFilteredModule;