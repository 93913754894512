import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useParams } from 'react-router-dom';

import Header from '../Header.js';
import MedicReportFeedbackBox from '../modules/MedicReportFeedbackBox';
import Footer from '../Footer.js';

const MedicReportPage = ({ user }) => {
    let { reportId } = useParams();
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="page-container">
            <Header user={user} displayHomeButton={true} displayQiButton={false}/>
            <div className='box-spacer'/>
            <MedicReportFeedbackBox user={user} reportId={reportId} isSmallScreen={isSmallScreen} />
            <Footer />
        </div>
    );
};

export default MedicReportPage; 