import React from 'react';
import ReportDataModule from '../modules/ReportDataModule.js';
import './Box.css';

const ReportViewBox = ({user, reportId, isSmallScreen}) => {
    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>
            <h1 className='box-title'> Report View </h1>
            <ReportDataModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
        </div>
    );
}

export default ReportViewBox; 