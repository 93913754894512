import React, { useState, useEffect } from 'react';

import Header from '../Header.js';
import Footer from '../Footer.js';
import MedicInfoBox from '../boxes/MedicInfoBox.js';

const MedicInfoPage = ({ user }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600); // screen width cutoff
        };
    
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header user={user} displayHomeButton={true}/>
            <div className='box-spacer'/>
            <MedicInfoBox user={user} isSmallScreen={isSmallScreen}/>
            <Footer />
        </div>
    );
};

export default MedicInfoPage; 