import React, { useState, useCallback } from 'react';
import './AccordionListElement.css';

// Utility function to create a path-based update mechanism
const updateNestedObject = (obj, path, value) => {
  // Create a deep copy of the object to avoid direct mutation
  const updated = JSON.parse(JSON.stringify(obj));
  
  // Split the path into segments
  const pathSegments = path.split('.');
  
  // Traverse to the second-to-last segment
  let current = updated;
  for (let i = 0; i < pathSegments.length - 1; i++) {
    const segment = pathSegments[i];
    
    // Handle array notation (e.g., array[2])
    const arrayMatch = segment.match(/(.+)\[(\d+)\]/);
    if (arrayMatch) {
      const [, arrayName, index] = arrayMatch;
      current = current[arrayName][parseInt(index)];
    } else {
      current = current[segment];
    }
  }
  
  // Update the final segment
  const finalSegment = pathSegments[pathSegments.length - 1];
  const finalArrayMatch = finalSegment.match(/(.+)\[(\d+)\]/);
  
  if (finalArrayMatch) {
    const [, arrayName, index] = finalArrayMatch;
    current[arrayName][parseInt(index)] = value;
  } else {
    current[finalSegment] = value;
  }
  
  return updated;
};

const AccordionListElement = ({ reportJSON: initialReportJSON, readOnly = true }) => {
    const [reportJSON, setReportJSON] = useState(initialReportJSON);
    const [expandedSections, setExpandedSections] = useState({});

    // Update handler for modifying values
    const handleUpdate = useCallback((path, value) => {
        if (!readOnly) {
            setReportJSON(prevData => updateNestedObject(prevData, path, value));
        }
    }, [readOnly]);

    const toggleSection = (sectionKey) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionKey]: !prev[sectionKey]
        }));
    };

    // Recursive function to render JSON elements with path tracking
    const renderElement = (value, key, path = '', depth = 0) => {
        // Skip rendering if key is 'Signature Last Name' or 'Signature First Name'
        if (key === 'Signature Last Name' || key === 'Signature First Name') {
            return 'removed';
        }

        const currentPath = path ? `${path}.${key}` : key;

        // Handle primitive values (strings, numbers, booleans)
        if (typeof value !== 'object' || value === null) {
            return (
                <div className="element-value">
                    {readOnly ? (
                        <span className="read-only-text">{value?.toString() || ''}</span>
                    ) : (
                        <select
                            value={value?.toString() || ''}
                            onChange={(e) => handleUpdate(currentPath, e.target.value)}
                            className="data-input"
                        >
                            <option value="Option 1">Option 1</option>
                            <option value="Option 2">Option 2</option>
                            <option value="Option 3">Option 3</option>
                        </select>
                    )}
                </div>
            );
        }

        // Handle arrays
        if (Array.isArray(value)) {
            return (
                <div className={`nested-content depth-${depth % 10}`}>
                    {value.map((item, index) => {
                        const arrayPath = `${currentPath}[${index}]`;
                        
                        // If array item is a primitive
                        if (typeof item !== 'object' || item === null) {
                            return (
                                <div key={index} className="list-element">
                                    <div className="medium-div">
                                        {readOnly ? (
                                            <span className="read-only-text">{item?.toString() || ''}</span>
                                        ) : (
                                            <select
                                                value={item?.toString() || ''}
                                                onChange={(e) => handleUpdate(arrayPath, e.target.value)}
                                                className="data-input"
                                            >
                                                <option value="Option 1">Option 1</option>
                                                <option value="Option 2">Option 2</option>
                                                <option value="Option 3">Option 3</option>
                                            </select>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                        
                        // If array item is an object
                        return (
                            <div key={index} className="list-element">
                                <div className={`nested-content depth-${(depth + 1) % 10}`}>
                                    {Object.entries(item).map(([itemKey, itemValue]) => (
                                        <div key={itemKey} className="list-element">
                                            <div className="small-div">
                                                <strong>{itemKey}:</strong>
                                            </div>
                                            <div className="medium-div">
                                                {renderElement(itemValue, itemKey, arrayPath, depth + 1)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        // Handle objects
        return (
            <div className={`nested-content depth-${depth % 10}`}>
                {Object.entries(value).map(([nestedKey, nestedValue]) => (
                    <div key={nestedKey} className="list-element">
                        <div className="small-div">
                            <strong>{nestedKey}:</strong>
                        </div>
                        <div className="medium-div">
                            {renderElement(nestedValue, nestedKey, currentPath, depth + 1)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="list-element-container">
            <div className="list-element">
                <div className="small-div" style={{ display: 'flex', flexDirection: 'column' }}>
                    {Object.entries(reportJSON).map(([key, value]) => (
                        <div key={key} className="accordion-section">
                            <div 
                                className="accordion-header"
                                onClick={() => toggleSection(key)}
                            >
                                <div className="small-div">
                                    <strong>{key}</strong>
                                </div>
                                <div className="tiny-div">
                                    {expandedSections[key] ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                                </div>
                            </div>
                            {expandedSections[key] && (
                                <div className="accordion-content">
                                    {renderElement(value, key)}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="update-actions">
                <button 
                    onClick={() => console.log(JSON.stringify(reportJSON, null, 2))}
                    className="update-button"
                >
                    Log Updated JSON
                </button>
            </div> */}
        </div>
    );
};

export default AccordionListElement;