import React from "react";

import LandingHeader from "../LandingHeader";
import LandingFooter from "../LandingFooter";
import AboutTimeline from "./AboutTimeline";
import { useNavigate } from "react-router-dom";

import './AboutPage.css';

const AboutPage = () => {
    return (
        <>
            <LandingHeader page="about"/>
            <div className="about-page">
                <div className="about-banner">
                    <div className="content">
                        <h1>This is CADMUS</h1>
                        <p>We aim to enhance the quality of patient care through cost-effective analytics that empower EMS professionals to optimize their workflow. </p>
                    </div>
                </div>
                <AboutTimeline />
            </div>
            <LandingFooter />
        </>
    )
}

export default AboutPage;