import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import MedicInfoListElement from '../list_elements/MedicInfoListElement.js';
import './Module.css';
import LoadingListElement from '../list_elements/LoadingListElement.js';
const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");
const StatusCodes = require('../../../status-codes/status-codes.js');

const MedicInfoModule = ({ user, isSmallScreen }) => {
    const [medicsData, setMedicsData] = useState(null);

    const getAllAgencyMedics = async (agencyId) => {
        const url = URLS.GET_MEDICS_FOR_USER;
        const dict = {
            uid: user.uid,
            agency_id: agencyId,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status;

            if (Network.checkValidStatusAndResponse(response)) {
                // success case
            } else if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error");
            } else {
                console.log("Unknown Error");
            }
        });

        return responseData;
    };

    const handleMedicUpdate = async (medicData) => {
        const url = URLS.UPDATE_MEDIC_INFO;
        const dict = {
            uid: user.uid,
            medic_uid: medicData.medicUid,
            medic_name: medicData.medicName,
            medic_email: medicData.medicEmail,
            CLIENT_ACCESS_KEY: key
        };

        try {
            await Network.post(url, dict, (response) => {
                const status = response.status;

                if (Network.checkValidStatusAndResponse(response)) {
                    // Refresh the medics list after successful update
                    getAllAgencyMedics(user.uid)
                        .then((data) => {
                            setMedicsData(data);
                        });
                } else if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
                    console.log("Server Error");
                } else {
                    console.log("Unknown Error");
                }
            });
        } catch (error) {
            console.log('Error updating medic:', error);
        }
    };

    useEffect(() => {
        getAllAgencyMedics(user.uid)
            .then((data) => {
                setMedicsData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, [user.uid]);

    const createMedicInfoListElement = (medicName, medicUid, medicCrewMemberId, medicEmail) => {
        return (
            <MedicInfoListElement
                key={medicUid}
                medicName={medicName}
                medicUid={medicUid}
                medicCrewMemberId={medicCrewMemberId}
                medicEmail={medicEmail}
                onSubmit={handleMedicUpdate}
            />
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div className="list-header">
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Medic Name </div>
                </div>
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Medic ID </div>
                </div>
                <div className='medium-div'>
                    <div style={{fontWeight: "bold"}}> Email </div>
                </div>
                <div className='button-spacer'></div>
            </div>

            {medicsData ? (
                medicsData.map((medic) => 
                    createMedicInfoListElement(
                        medic.medic_name,
                        medic.medic_uid,
                        medic.crew_member_id,
                        medic.medic_email
                    )
                )
            ) : (
                <LoadingListElement />
            )}
        </div>
    );
};

export default MedicInfoModule; 