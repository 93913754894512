import React from 'react';

import ReportInfoModule from '../modules/ReportInfoModule.js';
import ReportTimesModule from '../modules/ReportTimesModule.js';
import ReportNarrativeModule from '../modules/ReportNarrativeModule.js';
import ReportAiNarrativeSuggestionsModule from '../modules/ReportAiNarrativeSuggestionsModule.js';
import ReportAiSuggestionsModule from '../modules/ReportAiSuggestionsModule.js';
import ReportChecksModule from '../modules/ReportChecksModule.js';
import GoToMedicViewModule from '../modules/GoToMedicViewModule.js';
import './Box.css';

const ReportFeedbackBox = ({user, reportId, isSmallScreen}) => {
    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>
            <h1 className='box-title'> Report Feedback </h1>
            {user.subscriptions.includes('CADMUS_AUDITOR') && <GoToMedicViewModule reportId={reportId} isSmallScreen={isSmallScreen} />}
            <ReportInfoModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>
            {/* <ReportTimesModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} /> */}
            {!user.subscriptions.includes('CADMUS_AUDITOR') && <ReportNarrativeModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>}
            {user.subscriptions.includes('CADMUS_AUDITOR') && <ReportAiSuggestionsModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>}
            {/* {user.subscriptions.includes('CADMUS_AUDITOR') && <ReportAiNarrativeSuggestionsModule user={user} reportId={reportId} isSmallScreen={isSmallScreen}/>} */}
            <ReportChecksModule user={user} reportId={reportId} isSmallScreen={isSmallScreen} />
        </div>
    );
}

export default ReportFeedbackBox;