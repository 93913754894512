import React from 'react';
import './Tag.css';

const TypeTag = ({ reportType, isButton, onButtonClick }) => {
    const tagNames = {
        "non-emergent": "Non-Emergent",
        "non-acute": "Non-Acute/Other",
        "refusal": "Refusal",
        "cancelled": "Cancelled/No Patient",
        "general-emergent": "Emergent",

        "stroke": "Stroke",
        "cardiac": "Cardiac",
        "arrest": "Arrest",
        "stemi": "STEMI",
        "trauma": "Trauma",
        "respiratory": "Resp",
        "seizure": "Seizure",
        "syncope": "Syncope",
        "tbi": "TBI",
        "pediatrics": "Ped",
        "hypoglycemia": "LBG",
        "airway": "Airway",
        "asthma": "Asthma",
        "narcotics": "Narcs",
        "high-risk-refusal": "HRR",

        "ground-transport": "Ground",

        "ai-flag": "Flag",
        // "narrative-suggestions": "NarSug",
        "suggestions": "NewSug",
        "protocol-flag": "Protocol"
    };

    // if TBI or trauma, extract the value and override the color based on severity

    return (
        <div>
            {isButton ? (
                // <button className={`tag-button ${reportType}`} onClick={() => onButtonClick(reportType)}></button> // TODO: add onClick functionality
                <button className={`tag-button ${reportType}`}>
                    {tagNames[reportType]}
                </button>
            ) : (
                <div className={`tag ${reportType}`}>
                    {tagNames[reportType]}
                </div>
            )}
        </div>
    );
};

export default TypeTag;
