import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import SuggestionListElement from '../list_elements/SuggestionListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const ReportAiSuggestionsModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);
    const [suggestionData, setSuggestionData] = useState(null);
    const [suggestionToUpdate, setSuggestionToUpdate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSplit, setIsOpenSplit] = useState(false);
    const [oldNarrative, setOldNarrative] = useState('no old narrative set');
    const [newNarrative, setNewNarrative] = useState('no new narrative set');

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const handleOpenSplit = () => {
        setIsOpenSplit(!isOpenSplit);
    }

    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const getOneReportSuggestions = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_SUGGESTIONS;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });
        // console.log('Narrative Suggestions JSON:', responseData);
        return responseData;
    };

    const updateSuggestionRating = async (suggestion) => { // handle flag or check determination here
        
        let rating = suggestion.ratingStatus;

        console.log('Current rating being pushed:', rating);

        const url = URLS.UPDATE_SUGGESTION_RATING;

        const dict = {
            rating: rating,
            suggestions_id: suggestion.suggestionData.suggestions_id,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        // console.log("response data:", responseData);
        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                if (data[0].narrative_formatted !== null) setOldNarrative(data[0].narrative_formatted);
                if (data[0].narrative_edited !== null) setNewNarrative(data[0].narrative_edited);
                setReportData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        getOneReportSuggestions(reportId)
            .then((data) => {
                setSuggestionData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        // do something
        if (suggestionToUpdate !== null && suggestionToUpdate !== undefined) {
            console.log('suggestionToUpdate:', suggestionToUpdate);
            updateSuggestionRating(suggestionToUpdate);
        }
    }, [suggestionToUpdate]);

    const handleRatingButtonClick = (suggestion) => {
        console.log('suggestion to be passed:', suggestion);
        setSuggestionToUpdate(suggestion);
    };

    const createSuggestionListElement = (data) => {
        return (
            data.validated === 1 ? ( // always show for now
                <SuggestionListElement
                    suggestionData={data}
                    onButtonClick={handleRatingButtonClick}
                    />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>NEW Narrative Suggestions!</h2>
            </div>
            <div style={{ textAlign: 'left' }}>Rate these in the same way that you have been:</div>
            <div style={{ textAlign: 'left' }}><strong> Accept: </strong>I would give this feedback to the medic</div>
            <div style={{ textAlign: 'left' }}><strong> Reject: </strong>This feedback would not be useful to the medic</div>
            <div style={{ textAlign: 'left' }}><strong> Misleading: </strong>This feedback would be misleading for the medic</div>
            <br></br>
            <div>
                {suggestionData ? (
                    <div>
                        {suggestionData.map((suggestion) => (createSuggestionListElement(suggestion)))}
                    </div>
                ) : (
                    <div>Loading Report Data...</div>
                )}

                <div className="enter-button" onClick={e => handleOpenSplit()}>
                    Click here to view suggested changes to the narrative: 
                    <i className={isOpenSplit ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"}></i>
                </div>
                {isOpenSplit && (
                    <ReactDiffViewer
                        oldValue={oldNarrative}
                        newValue={newNarrative}
                        splitView={true}
                        disableWordDiff={false}
                        hideLineNumbers={true}
                        hideMarkers={true}
                        showDiffOnly={false}
                        compareMethod={DiffMethod.WORDS}
                    />
                )}
            </div>
        </div>
    );
}

export default ReportAiSuggestionsModule;