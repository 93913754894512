import React from "react";
import PathDrawing from "./LandingAnimation";
import "./LandingTitle.css";

const LandingTitle = () => {
    
    const graphParams = {
        width: 400,
        height: 400
    }
    
    return (
        <div className="landingTitle">
            <div className="content row">
                <div className="col text">
                    <h1>Upgrade Your EMS Quality Workflow Today</h1>
                    
                    {/* <a className="demo-btn" href="#demo"> */}
                    <a className="demo-btn" href="#demos">
                        View a demo
                        <i className="fa-solid fa-chevron-right"></i>
                    </a>
                    
                </div>
                <div className="col graphs">
                    <PathDrawing />
                </div>
            </div>
        </div>
    )
}

export default LandingTitle;