import React, { useState } from 'react';
import '../modules/Module.css';

const MedicInfoListElement = ({ medicName, medicUid, medicCrewMemberId, medicEmail, onSubmit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [nameInput, setNameInput] = useState(medicName || '');
    const [emailInput, setEmailInput] = useState(medicEmail || '');
    const [inputErrors, setInputErrors] = useState({ name: '', email: '' });

    // Improved email regex to better handle various email formats
    const emailTest = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Name validation regex - allows letters, spaces, hyphens, and apostrophes
    const nameTest = /^[a-zA-Z\s'-]{2,50}$/;

    const validateInputs = () => {
        const errors = { name: '', email: '' };
        let isValid = true;

        // Validate name
        if (!nameInput.trim()) {
            errors.name = 'Name is required';
            isValid = false;
        } else if (!nameTest.test(nameInput.trim())) {
            errors.name = 'Invalid name format';
            isValid = false;
        }

        // Validate email
        if (!emailInput.trim()) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!emailTest.test(emailInput.trim())) {
            errors.email = 'Invalid email format';
            isValid = false;
        }

        setInputErrors(errors);
        return isValid;
    };

    const handleButtonClick = () => {
        if (isEditing) {
            // If we're currently editing, validate and submit the changes
            if (validateInputs()) {
                onSubmit({
                    medicUid: medicUid,
                    medicName: nameInput.trim(),
                    medicEmail: emailInput.trim()
                });
                setIsEditing(false);
                setInputErrors({ name: '', email: '' });
            }
        } else {
            // If we're not editing, enter edit mode
            setIsEditing(true);
            setInputErrors({ name: '', email: '' });
        }
    };

    return (
        <div className="list-element">
            <div className='medium-div'>
                {isEditing ? (
                    <div className="input-container">
                        <input
                            type="text"
                            value={nameInput}
                            onChange={(e) => setNameInput(e.target.value)}
                            placeholder="Enter medic name"
                            className={`standard-input ${inputErrors.name ? 'error-input' : ''}`}
                        />
                        {inputErrors.name && <div className="error-message">{inputErrors.name}</div>}
                    </div>
                ) : (
                    <span>{medicName}</span>
                )}
            </div>
            <div className='medium-div'>
                {medicCrewMemberId}
            </div>
            <div className='medium-div'>
                {isEditing ? (
                    <div className="input-container">
                        <input
                            type="email"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            placeholder="Enter email"
                            className={`standard-input ${inputErrors.email ? 'error-input' : ''}`}
                        />
                        {inputErrors.email && <div className="error-message">{inputErrors.email}</div>}
                    </div>
                ) : (
                    <span>{medicEmail}</span>
                )}
            </div>
            <button 
                className="box-button"
                onClick={handleButtonClick}
            >
                {isEditing ? 'Submit Info' : 'Change Info'}
            </button>
        </div>
    );
};

export default MedicInfoListElement; 