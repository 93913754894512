import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import AccordionListElement from '../list_elements/AccordionListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const { NEMSIS_KEY_CODES } = require("../../../models/nemsis_key_codes.js")
const { NEMSIS_VALUE_CODES } = require("../../../models/nemsis_value_codes.js")

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const convertNemsisCodesToValues = (obj) => {
    if (!obj || typeof obj !== 'object') {
        return obj;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
        return obj.map(item => {
            // If item is a string that's a NEMSIS code, convert it
            if (typeof item === 'string' && NEMSIS_VALUE_CODES[item]) {
                return NEMSIS_VALUE_CODES[item];
            }
            // Otherwise recursively process the item
            return convertNemsisCodesToValues(item);
        });
    }

    // Handle objects
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object') {
            result[key] = convertNemsisCodesToValues(value);
        } else if (typeof value === 'string' && NEMSIS_VALUE_CODES[value]) {
            result[key] = NEMSIS_VALUE_CODES[value];
        } else {
            result[key] = value;
        }
    }
    return result;
}

const convertNemsisCodesToKeys = (obj) => {
    if (!obj || typeof obj !== 'object') {
        return obj;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
        return obj.map(item => {
            // Recursively process array items
            return convertNemsisCodesToKeys(item);
        });
    }

    // Handle objects
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
        // Check if the key is a NEMSIS code that needs conversion
        const newKey = NEMSIS_KEY_CODES[key] || key;
        
        if (typeof value === 'object') {
            // Recursively process nested objects
            result[newKey] = convertNemsisCodesToKeys(value);
        } else {
            // Keep the value but use converted key
            result[newKey] = value;
        }
    }
    return result;
}

const ReportDataModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);

    // function that gets one reports from the server
    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                try {
                    const parsedJSON = JSON.parse(data[0].report_json);
                    const convertedParsedJSON = convertNemsisCodesToKeys(parsedJSON);
                    const convertedParsedJSONValues = convertNemsisCodesToValues(convertedParsedJSON);
                    setReportData(convertedParsedJSONValues);
                } catch (error) {
                    console.log('Error parsing JSON:', error);
                    setReportData(null);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Report Data</h2>
            </div>
            {reportData ? (
                <AccordionListElement reportJSON={reportData} />
            ) : (
                <div>Loading Report Data...</div>
            )}
        </div>
    );
}

export default ReportDataModule; 