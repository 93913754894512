import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import SuggestionListElement from '../list_elements/SuggestionListElement.js';
import CheckListElement from '../list_elements/CheckListElement.js';
import LoadingListElement from '../list_elements/LoadingListElement.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const MedicReportFeedbackModule = ({user, reportId, isSmallScreen}) => {
    const [reportData, setReportData] = useState(null);
    const [suggestionData, setSuggestionData] = useState(null);
    const [suggestionToUpdate, setSuggestionToUpdate] = useState(null);
    const [childInputs, setChildInputs] = useState({});
    const [isOpenSplit, setIsOpenSplit] = useState(false);
    const [oldNarrative, setOldNarrative] = useState('no old narrative set');
    const [newNarrative, setNewNarrative] = useState('no new narrative set');
    const [isRequestingNarrative, setIsRequestingNarrative] = useState(false);
    const [requestTimeout, setRequestTimeout] = useState(null);
    const [requestInterval, setRequestInterval] = useState(null);
    const [allChecksData, setAllChecksData] = useState(null);
    const [checkFlagToUpdate, setCheckFlagToUpdate] = useState(null);

    // function that gets one report's narrative suggestions from the server
    const getOneReportSuggestions = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_SUGGESTIONS;
        const dict = {
            // uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    // function that gets one report's checks from the server
    const getOneReportChecks = async (reportId) => {
        const url = URLS.GET_ONE_REPORT_CHECKS;
        const dict = {
            // uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const updateSuggestionRating = async (suggestion) => {
        let rating = suggestion.ratingStatus;
        console.log('Current rating being pushed:', rating);

        const url = URLS.UPDATE_SUGGESTION_RATING;
        const dict = {
            rating: rating,
            suggestions_id: suggestion.suggestionData.suggestions_id,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const updateSuggestionMedicText = async (suggestions) => {
        const url = URLS.UPDATE_SUGGESTION_MEDIC_TEXT;
        const dict = {
            suggestions: suggestions,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const status = response.status;

            if (Network.checkValidStatusAndResponse(response)) {
                // Success
                console.log("Successfully updated medic text");
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error");
            } else {
                console.log("Unknown Error");
            }
        });
        return responseData;
    };

    const updateNarrativeEdited = async (suggestions) => {
        const url = URLS.UPDATE_NARRATIVE_EDITED;
        const dict = {
            report_id: reportId,
            suggestions: suggestions,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const status = response.status;

            if (Network.checkValidStatusAndResponse(response)) {
                console.log("Successfully updated narrative");
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error");
            } else {
                console.log("Unknown Error");
            }
        });
        return responseData;
    };

    const handleOpenSplit = () => {
        setIsOpenSplit(!isOpenSplit);
    }

    const getOneReport = async (reportId) => {
        const url = URLS.GET_ONE_REPORT;
        const dict = {
            // uid: user.uid,
            report_id: reportId,
            CLIENT_ACCESS_KEY: key
        };
        let responseData;

        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    useEffect(() => {
        getOneReportSuggestions(reportId)
            .then((data) => {
                setSuggestionData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (suggestionToUpdate !== null && suggestionToUpdate !== undefined) {
            console.log('suggestionToUpdate:', suggestionToUpdate);
            updateSuggestionRating(suggestionToUpdate);
        }
    }, [suggestionToUpdate]);

    useEffect(() => {
        getOneReport(reportId)
            .then((data) => {
                if (data[0].narrative_formatted !== null) setOldNarrative(data[0].narrative_formatted);
                if (data[0].narrative_edited !== null) setNewNarrative(data[0].narrative_edited);
                setReportData(data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        getOneReportChecks(reportId)
            .then((results) => {
                setAllChecksData(results);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (checkFlagToUpdate !== null && checkFlagToUpdate !== undefined) {
            updateOverriddenStatus(
                checkFlagToUpdate.checkFlagData,
                checkFlagToUpdate.overriddenStatus,
                checkFlagToUpdate.objectType
            );
        }
    }, [checkFlagToUpdate]);

    const handleRatingButtonClick = (suggestion) => {
        console.log('suggestion to be passed:', suggestion);
        setSuggestionToUpdate(suggestion);
    };

    const handleInputFromChild = (suggestionId, input) => {
        setChildInputs(prev => ({
            ...prev,
            [suggestionId]: input
        }));
    };

    const handleSubmitAllFeedback = async (e) => {
        e.preventDefault();
        console.log('All feedback inputs:', childInputs);
        
        const suggestions = Object.entries(childInputs).map(([suggestions_id, medic_text]) => ({
            suggestions_id: parseInt(suggestions_id),
            medic_text
        }));

        const suggestionsWithText = suggestions.filter(s => s.medic_text && s.medic_text.trim());
        
        if (suggestionsWithText.length > 0) {
            setIsRequestingNarrative(true);
            setIsOpenSplit(false);
            try {
                await updateSuggestionMedicText(suggestionsWithText);

                // Get the updated suggestions
                const updatedSuggestions = await getOneReportSuggestions(reportId);
                setSuggestionData(updatedSuggestions);

                // Start the narrative editing process
                await updateNarrativeEdited(updatedSuggestions);

                // Start polling for narrative updates
                startPollingForNarrative();

            } catch (error) {
                console.error('Error updating feedback:', error);
            }
        } else {
            console.log('no text to submit');
        }
    };

    const startPollingForNarrative = () => {
        // setIsRequestingNarrative(true);
        let attempts = 0;
        const maxAttempts = 6;
        
        // Clear any existing intervals/timeouts
        if (requestInterval) {
            console.log('Clearing existing interval:', requestInterval);
            clearInterval(requestInterval);
        }
        if (requestTimeout) clearTimeout(requestTimeout);
        
        // Create a direct reference to the interval ID that will be used for cleanup
        let currentIntervalId;
        
        currentIntervalId = setInterval(async () => {
            attempts++;
            console.log('attempting to get the edited narrative, attempt:', attempts);
            
            try {
                const data = await getOneReport(reportId);
                if (data[0].narrative_edited !== null) {
                    setNewNarrative(data[0].narrative_edited);
                    console.log('Found narrative, cleaning up interval:', currentIntervalId);
                    clearInterval(currentIntervalId); // Direct cleanup
                    setRequestInterval(null);
                    setIsRequestingNarrative(false);
                }
            } catch (error) {
                console.error('Error polling for narrative:', error);
            }
            
            if (attempts >= maxAttempts) {
                console.log('max attempts reached, cleaning up interval:', currentIntervalId);
                clearInterval(currentIntervalId); // Direct cleanup
                setRequestInterval(null);
                setIsRequestingNarrative(false);
            }
        }, 5000);
        
        // Store the interval ID
        currentIntervalId = currentIntervalId;
        setRequestInterval(currentIntervalId);
        
        // Similar direct approach for timeout
        const timeoutId = setTimeout(() => {
            console.log('Timeout reached, cleaning up interval:', currentIntervalId);
            clearInterval(currentIntervalId); // Direct cleanup
            setRequestInterval(null);
            setIsRequestingNarrative(false);
        }, 30000);
        
        setRequestTimeout(timeoutId);
    };

    // Clean up intervals/timeouts when component unmounts
    useEffect(() => {
        return () => {
            if (requestInterval) clearInterval(requestInterval);
            if (requestTimeout) clearTimeout(requestTimeout);
        };
    }, [requestInterval, requestTimeout]);

    const createSuggestionListElement = (data) => {
        return (
            data.validated === 1 ? (
                <SuggestionListElement
                    key={data.suggestions_id}
                    suggestionData={data}
                    onButtonClick={handleRatingButtonClick}
                    showFeedbackInput={true}
                    onInputChange={handleInputFromChild}
                />
            ) : null
        );
    };

    const updateOverriddenStatus = async (checkFlagData, overriddenStatus, objectType) => {
        console.log('objectType status update:', objectType);
        let currentOverriddenStatus = overriddenStatus ? 'overridden' : 'unoverridden';
        console.log('Current Overridden Status being pushed:', currentOverriddenStatus);
        
        let dict = null;
        let url = null;

        // check type here
        if (objectType === 'check') {
            url = URLS.UPDATE_CHECK_OVERRIDE;
            dict = {
                overridden_status: currentOverriddenStatus,
                agency_uid: checkFlagData.reports_agencies_uid,
                report_id: checkFlagData.reports_report_id,
                test_metadata_id: checkFlagData.test_metadata_test_metadata_id,
                CLIENT_ACCESS_KEY: key
            };
        } else if (objectType === 'ai_flag') {
            url = URLS.UPDATE_AI_FLAG_OVERRIDE;
            dict = {
                overridden_status: currentOverriddenStatus,
                report_id: checkFlagData.reports_report_id,
                ai_flag_id: checkFlagData.ai_flags_id,
                CLIENT_ACCESS_KEY: key
            };
        }

        let responseData;
        await Network.post(url, dict, (response) => {
            responseData = response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something
            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
            } else {
                console.log("Unknown Error")
            }
        });
        return responseData;
    };

    const handleOverrideButtonClick = (checkOrFlag) => {
        let checkFlagToUpdate = {
            checkFlagData: checkOrFlag['checkFlagData'],
            overriddenStatus: checkOrFlag['overriddenStatus'],
            objectType: checkOrFlag['objectType']
        };
        setCheckFlagToUpdate(checkFlagToUpdate);
    };

    const createCheckListElement = (checkData) => {
        return (
            // do not display passed checks to save on space
            checkData['result'] !== null && checkData['result'] !== 'null' && checkData['result'] !== 1 &&
            (checkData['impact_attribute'] !== 'measure' && checkData['impact_attribute'] !== 'quant-measure') ? (
                <CheckListElement
                    checkData={checkData}
                    onButtonClick={handleOverrideButtonClick}
                />
            ) : null
        );
    };

    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <div>
                <h2 className='module-title'>Feedback</h2>
                {allChecksData && allChecksData.length > 0 ? (
                    <div>
                        {allChecksData.map((checkData) => (createCheckListElement(checkData)))}
                    </div>
                ) : (
                    <div>Loading Report Checks...</div>
                )}
            </div>
            <div>
                {suggestionData ? (
                    <div>
                        {suggestionData.map((suggestion) => (createSuggestionListElement(suggestion)))}
                    </div>
                ) : (
                    <div>Loading Report Data...</div>
                )}
                
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    <button 
                        onClick={handleSubmitAllFeedback}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Submit All Feedback
                    </button>
                    {isRequestingNarrative && (
                        <LoadingListElement height={60} backgroundColor="white"/>
                    )}
                </div>
                {!isRequestingNarrative && (
                    <>
                        <div className="enter-button" style={{ marginTop: '20px' }} onClick={e => handleOpenSplit()}>
                            Click here to view suggested changes to the narrative: 
                            <i className={isOpenSplit ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"}></i>
                        </div>
                        {isOpenSplit && (
                            <ReactDiffViewer
                                oldValue={oldNarrative}
                                newValue={newNarrative}
                                splitView={true}
                                disableWordDiff={false}
                                hideLineNumbers={true}
                                hideMarkers={true}
                                showDiffOnly={false}
                                compareMethod={DiffMethod.WORDS}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MedicReportFeedbackModule; 